import React, { useState } from 'react';
import { Form, Input, Button, Typography, Alert } from 'antd';
import { sendPasswordResetEmail } from 'firebase/auth'; // Import the function
import { auth } from '../firebaseConfig';

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      // Call the Firebase function to send a password reset email.
      await sendPasswordResetEmail(auth, email, {
        // The URL to redirect to after the password reset is complete.
        url: 'https://app.spruceai.app/login',
          handleCodeInApp: true,
      });
      setSuccess('Password reset email sent successfully.');
    } catch (err) {
      console.error('Forgot password error:', err);
      setError('Failed to send reset email. Please check the email address.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        padding: '20px',
      }}
    >
      <div style={{ textAlign: 'center', marginBottom: '30px' }}>
        <img src="/icons/LogoNoBackground.png" alt="Spruce Logo" style={{ width: '150px', marginBottom: '10px' }} />
      </div>

      <div
        style={{
          backgroundColor: '#fff',
          padding: '30px',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: '400px',
        }}
      >
        <Title level={4} style={{ textAlign: 'center', marginBottom: '20px' }}>
          Forgot Password
        </Title>

        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: '20px' }} />}
        {success && <Alert message={success} type="success" showIcon style={{ marginBottom: '20px' }} />}

        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please enter your email!' },
              { type: 'email', message: 'Invalid email!' },
            ]}
          >
            <Input
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: '100%', marginTop: '20px' }}
            >
              Send Reset Email
            </Button>
          </Form.Item>
        </Form>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Text>
            Remembered your password?{' '}
            <a href="/login" style={{ color: '#007bff' }}>
              Sign in
            </a>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
