import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Layout, Menu, Image } from 'antd';
import './Navbar.css';

const { Header } = Layout;

const Navbar = ({ isAuthenticated }) => {
  const location = useLocation();
  const selectedKey = location.pathname.startsWith('/home') ? '/home' : location.pathname;

  return (
    <Header className="navbar">
      <div className="navbar-logo">
        <NavLink to="/home">
          <Image
            src="/icons/LogoNoBackground.png"
            alt="Spruce Logo"
            preview={false}
            style={{ height: 40, width: 'auto' }}
          />
        </NavLink>
      </div>
      {isAuthenticated && (
        <Menu
          theme="light"
          mode="horizontal"
          selectedKeys={[selectedKey]}
          className="navbar-menu"
        >
          <Menu.Item key="/home">
            <NavLink to="/home">Home</NavLink>
          </Menu.Item>
          <Menu.Item key="/insights">
            <NavLink to="/insights">Insights</NavLink>
          </Menu.Item>
          <Menu.Item key="/charts">
            <NavLink to="/charts">Charts</NavLink>
          </Menu.Item>
          <Menu.Item key="/my-data-sources">
            <NavLink to="/my-data-sources">Data Sources</NavLink>
          </Menu.Item>
          <Menu.Item key="/settings">
            <NavLink to="/settings">Settings</NavLink>
          </Menu.Item>
          <Menu.Item key="/logout">
            <NavLink to="/logout">Logout</NavLink>
          </Menu.Item>
        </Menu>
      )}
    </Header>
  );
};

export default Navbar;
