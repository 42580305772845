import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Button,
  Modal,
  Input,
  message,
  Space,
  Tag,
  Typography,
  Spin,
  Form,
  Upload,
  Row,
  Col,
  Card,
} from 'antd';
import {
  SyncOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined, CheckCircleOutlined,
} from '@ant-design/icons';
import axios from '../fetchWithCsrf';
import { useNavigate } from 'react-router-dom';
import './MyDataSources.css';
import API_BASE_URL from "../config";

const { Title } = Typography;

const MyDataSources = () => {
  const [dataSources, setDataSources] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const [selectedSource, setSelectedSource] = useState(null);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [refreshingId, setRefreshingId] = useState(null);
  const navigate = useNavigate();

  const { TextArea } = Input;

  // Fetch connected data sources
  const fetchConnectedSources = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/datasources/`);
      setDataSources(response.data);
    } catch (error) {
      console.error('Error fetching connected data sources:', error);
      message.error('Failed to fetch data sources.');
    }
  }, []);


  useEffect(() => {
    fetchConnectedSources();
  }, [fetchConnectedSources]);

  const handleAddDataSource = () => {
    setIsModalVisible(true);
    setModalStep(1);
    setSelectedSource(null);
    form.resetFields();
  };


  const renderDataPreview = () => {
    return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  };


  const handleModalCancel = () => {
    setIsModalVisible(false);
    setModalStep(1);
    setSelectedSource(null);
    form.resetFields();
  };

  const handleSelectSource = (source) => {
    setSelectedSource(source);
    setModalStep(2);
  };

  const handleConnect = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('data_source_type', selectedSource.type);
      formData.append('customName', values.customName || `${selectedSource.name} Data Source`);

      Object.entries(values).forEach(([key, value]) => {
        if (key === 'file') {
          formData.append('file_path', value?.file?.originFileObj);
        } else {
          formData.append(key, value);
        }
      });

      const validationResponse = await axios.post(
        `${API_BASE_URL}/datasources/validate_connection/`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true }
      );

      if (validationResponse.status === 200) {
        const creationResponse = await axios.post(
          `${API_BASE_URL}/datasources/create_data_source/`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true }
        );

        if (creationResponse.status === 201) {
          message.success('Connection successful!');
          handleModalCancel();
          setIsSuccessModalVisible(true);
          fetchConnectedSources();
        } else {
          message.error(creationResponse.data.error || 'Connection unsuccessful.');
        }
      } else {
        message.error(validationResponse.data.error || 'Connection unsuccessful.');
      }
    } catch (error) {
      console.error('Error connecting data source:', error);
      message.error('Connection unsuccessful. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/datasources/${id}/`);
      setDataSources(dataSources.filter((ds) => ds.id !== id));
      message.success('Data source deleted successfully!');
    } catch (error) {
      console.error('Error deleting data source:', error);
      message.error('Error deleting data source.');
    }
  };

  const handleRename = async (id, newName) => {
    try {
      await axios.patch(`${API_BASE_URL}/datasources/${id}/rename/`, { name: newName });
      setDataSources(
        dataSources.map((ds) => (ds.id === id ? { ...ds, name: newName } : ds))
      );
      message.success('Data source renamed successfully!');
    } catch (error) {
      console.error('Error renaming data source:', error);
      message.error('Error renaming data source.');
    }
  };

  const handleRefresh = async (id) => {
    setRefreshingId(id);
    try {
      const response = await axios.post(`${API_BASE_URL}/datasources/${id}/refresh/`);
      if (response.data.message === 'Data source updated') {
        message.success('Data source updated successfully!');
        fetchConnectedSources();
      } else {
        message.info('No changes detected.');
      }
    } catch (error) {
      console.error('Error refreshing data source:', error);
      message.error('Error refreshing data source.');
    } finally {
      setRefreshingId(null);
    }
  };

  const typeMapping = {
      csv: 'CSV',
      mysql: 'MySQL',
      snowflake: 'Snowflake',
      bigquery: 'BigQuery',
      mssql: 'Microsoft SQL Server',
      postgresql: 'PostgreSQL',
  };

  const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => (
          <Space>
            {name}
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() =>
                Modal.confirm({
                  title: 'Rename Data Source',
                  content: (
                    <Input
                      defaultValue={name}
                      onChange={(e) => setSelectedSource({ ...record, newName: e.target.value })}
                      placeholder="Enter new name"
                    />
                  ),
                  onOk: () => handleRename(record.id, selectedSource?.newName || name),
                })
              }
            />
            <Button
              type="text"
              icon={<SyncOutlined spin={refreshingId === record.id} />}
              onClick={() => handleRefresh(record.id)}
            />
          </Space>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'data_source_type',
        key: 'type',
        sorter: (a, b) => a.data_source_type.localeCompare(b.data_source_type),
        render: (type) => (
          <Tag color="blue">{typeMapping[type] || type}</Tag>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'date',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        render: (date) => new Date(date).toLocaleDateString(),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
          <Space>
            <Button type="link" onClick={() => navigate(`/datasources/${record.id}/schema`)}>
              View Schema
            </Button>
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() =>
                Modal.confirm({
                  title: 'Confirm Deletion',
                  content: 'Are you sure you want to delete this data source?',
                  okText: 'Delete',
                  okType: 'primary', // Change to 'primary' for solid button style
                  okButtonProps: { danger: true }, // Add danger: true to make it red
                  cancelText: 'Cancel',
                  onOk: () => handleDelete(record.id),
                })
              }
            />
          </Space>
        ),
      },
  ];


  const availableSourcesList = [
    { name: 'PostgreSQL', icon: '/icons/Postgresql-icon.png', type: 'postgresql' },
    { name: 'MySQL', icon: '/icons/Mysql-icon.png', type: 'mysql' },
    { name: 'Snowflake', icon: '/icons/Snowflake-icon.png', type: 'snowflake' },
    { name: 'BigQuery', icon: '/icons/Bigquery-icon.png', type: 'bigquery' },
    { name: 'CSV', icon: '/icons/Csv-icon.png', type: 'csv' },
    { name: 'Microsoft SQL Server', icon: '/icons/Microsoftsql-icon.svg', type: 'mssql' },
  ];

  const renderFormFields = () => {
    if (!selectedSource) return null;

    switch (selectedSource.type) {
      case 'bigquery':
        return (
          <>
            <Form.Item
              label="Dataset ID"
              name="dataset_id"
              rules={[{ required: true, message: 'Please enter the Dataset ID' }]}
            >
              <Input placeholder="Enter Dataset ID" />
            </Form.Item>
            <Form.Item
              label="Service Account Credentials"
              name="credentials_json"
              rules={[{ required: true, message: 'Please enter your credentials' }]}
            >
              <TextArea rows={4} placeholder="Paste your Service Account credentials here" />
            </Form.Item>
          </>
        );
case 'snowflake':
      return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
          <Form.Item
            label="Data Source Name"
            name="customName"
            rules={[{ required: true, message: 'Please enter a data source name' }]}
          >
            <Input placeholder="Enter a custom name" />
          </Form.Item>
          <Form.Item
            label="Account"
            name="account"
            rules={[{ required: true, message: 'Please enter the account' }]}
          >
            <Input placeholder="Enter account (e.g., your_account)" />
          </Form.Item>
           <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please enter the username' }]}
          >
            <Input placeholder="Enter your username" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter the password' }]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
          <Form.Item
            label="Database"
            name="database"
            rules={[{ required: true, message: 'Please enter the database' }]}
          >
            <Input placeholder="Enter database" />
          </Form.Item>
          <Form.Item
            label="Warehouse"
            name="warehouse"
            rules={[{ required: true, message: 'Please enter the warehouse' }]}
          >
            <Input placeholder="Enter warehouse" />
          </Form.Item>
          <Form.Item
            label="Schema"
            name="schema"
            rules={[{ required: true, message: 'Please enter the schema' }]}
          >
            <Input placeholder="Enter schema" />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: 'Please enter the role' }]}
          >
            <Input placeholder="Enter role" />
          </Form.Item>
        </Space>
      );
    case 'postgresql':
    case 'mysql':
    case 'mssql':
      return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
          <Form.Item
            label="Data Source Name"
            name="customName"
            rules={[{ required: true, message: 'Please enter a data source name' }]}
          >
            <Input placeholder="Enter a custom name" />
          </Form.Item>
          <Form.Item
            label="Host"
            name="host"
            rules={[{ required: true, message: 'Please enter the host' }]}
          >
            <Input placeholder="e.g., localhost or IP address" />
          </Form.Item>
          <Form.Item
            label="Port"
            name="port"
            rules={[{ required: true, message: 'Please enter the port' }]}
          >
            <Input placeholder="e.g., 5432 for PostgreSQL, 3306 for MySQL, 1433 for MSSQL" />
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please enter the username' }]}
          >
            <Input placeholder="Enter the username" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter the password' }]}
          >
            <Input.Password placeholder="Enter the password" />
          </Form.Item>
          <Form.Item
            label="Database Name"
            name="database"
            rules={[{ required: true, message: 'Please enter the database name' }]}
          >
            <Input placeholder="Enter the database name" />
          </Form.Item>
        </Space>
      );
      case 'csv':
        return (
          <Form.Item
            label="File"
            name="file"
            valuePropName="file"
            getValueFromEvent={(e) => e}
            rules={[{ required: true, message: 'Please upload a CSV file' }]}
          >
            <Upload beforeUpload={() => false} accept=".csv">
              <Button icon={<PlusOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        );
      default:
        return null;
    }
  };

  return (
      <div className="my-data-sources-page">
        <Title level={4} style={{ marginBottom: '40px' }}>My Data Sources</Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="add-data-source-button"
          style={{ marginBottom: '40px' }}
          onClick={handleAddDataSource}
        >
          Add Data Source
        </Button>
        <Table
          columns={columns}
          dataSource={dataSources}
          rowKey={(record) => record.id}
          pagination={{ pageSize: 5 }}
        />
        <Modal
          title={null} // Remove the title
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
          width={800}
          destroyOnClose
          className="add-data-source-modal"
        >
          {modalStep === 1 && (
            <>
              <Title level={4} style={{ textAlign: 'center', marginBottom: '24px', fontWeight: 'normal' }}>
                Select Data Source Type
              </Title>
              <Row gutter={[16, 16]} style={{ padding: '0 16px' }}>
                {availableSourcesList.map((source) => (
                  <Col xs={24} sm={12} md={8} key={source.type}>
                    <Card
                      hoverable
                      className="data-source-card"
                      onClick={() => handleSelectSource(source)}
                      style={{
                        // Let the Card itself be normal:
                        textAlign: 'center'
                      }}
                    >
                      {/* Make the card body a Flex container */}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={source.icon}
                          alt={source.name}
                          style={{
                            width: '50px',
                            height: '50px',
                            marginBottom: '12px',
                            objectFit: 'contain',
                          }}
                        />
                        <p style={{ margin: 0 }}>{source.name}</p>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
          {modalStep === 2 && selectedSource && (
            <>
              <Title level={4} style={{ textAlign: 'center', marginBottom: '24px', fontWeight: 'normal' }}>
                Enter {selectedSource.name} Credentials
              </Title>
                {loading ? (
                  <div className="loading-screen">
                    {renderDataPreview()}
                  </div>
              ) : (
                <Form form={form} layout="vertical" onFinish={handleConnect} initialValues={{ customName: '' }}>
                  {renderFormFields()}
                  <Form.Item>
                    <Space style={{ marginTop: '16px' }}>
                      <Button onClick={handleModalCancel}>Cancel</Button>
                      <Button type="primary" htmlType="submit">
                        Connect
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              )}
            </>
          )}
        </Modal>
        <Modal
          title="Connection Successful"
          visible={isSuccessModalVisible}
          onCancel={() => setIsSuccessModalVisible(false)}
          footer={[
            <Button
              key="continue"
              type="primary"
              onClick={() => setIsSuccessModalVisible(false)}
            >
              Continue
            </Button>,
          ]}
        >
          <div
            className="success-modal-content"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {/* Use the Ant Design icon instead of an <img /> */}
            <CheckCircleOutlined
              style={{ fontSize: '64px', color: '#55BC6D', marginBottom: '20px' }}
            />
            <p>Your data source has been successfully connected.</p>
          </div>
        </Modal>
      </div>
  );
};

export default MyDataSources;
