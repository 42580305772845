import React, { useEffect, useState } from 'react';
import { Table, Button, Typography, Modal, Input, Tag, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import './UserAccessPage.css';
import API_BASE_URL from "../config";

const { Title } = Typography;
const { Option } = Select;

const UserAccessPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('Editor'); // default role for new invites
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/`, { withCredentials: true });
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  const handleInvite = async () => {
    if (!email) {
      return Modal.error({
        title: 'Invalid Input',
        content: 'Please enter a valid email address.',
      });
    }

    try {
      await axios.post(
        `${API_BASE_URL}/invite-user/`,
        { email, role },
        { withCredentials: true }
      );
      Modal.success({ title: 'Success', content: 'User invited successfully!' });
      setIsModalVisible(false);
      setEmail('');
      setRole('Editor'); // reset role to default after invite
      fetchUsers();
    } catch (error) {
      console.error('Error inviting user:', error);
      Modal.error({ title: 'Error', content: 'Failed to invite user. Please try again.' });
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => {
        let color = role === 'Admin' ? 'gold' : role === 'Editor' ? 'blue' : 'green';
        return <Tag color={color}>{role}</Tag>;
      },
    },
    {
      title: 'Joined',
      dataIndex: 'joined',
      key: 'joined',
    },
  ];

  return (
    <div className="user-access-page">
      <div className="page-header">
        <div className="back-button-container">
          <Typography.Link
            onClick={() => navigate('/settings')}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: 24,
              color: '#1677ff'
            }}
          >
            <LeftOutlined style={{ marginRight: 4 }} />
            Back
          </Typography.Link>
        </div>
        <Title level={4} style={{ marginBottom: 40 }} className="page-title">
          User Access
        </Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
          style={{ marginBottom: 40, marginLeft: 0 }}
          className="add-button"
        >
          Invite User
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={users}
        loading={loading}
        rowKey={(record) => record.id}
        bordered
        pagination={{ pageSize: 5 }}
        className="user-access-table"
      />
      <Modal
        title="Invite User"
        visible={isModalVisible}
        onOk={handleInvite}
        onCancel={() => setIsModalVisible(false)}
        okText="Invite"
        cancelText="Cancel"
      >
        <Input
          placeholder="Enter user email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <Select
          value={role}
          onChange={(value) => setRole(value)}
          style={{ width: '100%' }}
        >
          <Option value="Editor">Editor</Option>
          <Option value="Viewer">Viewer</Option>
        </Select>
      </Modal>
    </div>
  );
};

export default UserAccessPage;
