// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  // Show a loading indicator until Firebase auth status is determined
  if (loading) {
    return <div>Loading...</div>;
  }

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Optionally, verify additional conditions such as email verification
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (!currentUser || !currentUser.emailVerified) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
