import React, { useState, useEffect, useRef } from 'react';
import axios from '../fetchWithCsrf';
import Visualization from './Visualization';
import TableVisualization from "./TableVisualization";
import './ChatInterface.css';
import {
  Card, Button, Dropdown, Menu, Spin, Input, Modal, message, Row, Col
} from 'antd';
import {
  DownOutlined, LoadingOutlined, SendOutlined, DatabaseOutlined, FilterOutlined
} from '@ant-design/icons';
import API_BASE_URL from "../config";

const ALL_CHART_TYPES = [
  'Table',
  'Bar',
  'Line',
  'Area',
  'Pie',
  'Combo'
];

const ChatInterface = ({
  currentChat,
  messages,
  updateChat,
  currentChatDataSource,
  setCurrentChatDataSource,
  isFirstMessageSent,
  setIsFirstMessageSent,
}) => {
  const [userInput, setUserInput] = useState('');
  const [localMessages, setLocalMessages] = useState(messages);
  const [loading, setLoading] = useState(false);
  const [isNamingModalOpen, setIsNamingModalOpen] = useState(false);
  const [isDataModalOpen, setIsDataModalOpen] = useState(false);
  const [elementName, setElementName] = useState('');
  const [savingMessage, setSavingMessage] = useState(null);
  const [viewingMessage, setViewingMessage] = useState(null);
  const [setIsSuccess] = useState(false);
  const [databases, setDatabases] = useState({});
  const [chartLoadingStates] = useState({});
  const chatWindowRef = useRef(null);

  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [expandedDrillDown, setExpandedDrillDown] = useState({});

  // Scroll to bottom whenever localMessages changes
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [localMessages]);

  // Clear user input if currentChat changes
  useEffect(() => {
    setUserInput('');
  }, [currentChat]);

  // If `messages` changes externally, update local and turn off loading
  useEffect(() => {
    setLocalMessages(messages);
    setLoading(false);
  }, [messages]);

  // Fetch the list of possible databases (data sources)
  useEffect(() => {
    const fetchDatabases = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/datasources/`, {
          withCredentials: true,
        });
        console.log('Databases fetched:', response.data);

        const dbMap = {};
        response.data.forEach(db => {
          dbMap[db.id] = db.name;
        });
        setDatabases(dbMap);
      } catch (error) {
        console.error('Error fetching databases:', error);
      }
    };
    fetchDatabases();
  }, []);

  // Log savingMessage once it has a messageId
  useEffect(() => {
    if (savingMessage && savingMessage.messageId) {
      console.log("Message is ready for saving:", savingMessage);
    } else {
      console.log("Waiting for messageId to be assigned...");
    }
  }, [savingMessage]);

  /**
   * Dynamically fetch suggested questions from the backend,
   * but ONLY if:
   *   1) no message was ever sent (isFirstMessageSent = false),
   *   2) current chat has zero messages,
   *   3) we do have a valid currentChatDataSource selected.
   */
  useEffect(() => {
    const fetchSuggestedQuestions = async () => {
      if (!currentChatDataSource) {
        // No data source selected => skip
        return;
      }
      setLoadingSuggestions(true);
      try {
        const response = await axios.post(
          `${API_BASE_URL}/suggested-questions/`,
          { data_source_id: currentChatDataSource },
          { withCredentials: true }
        );
        setSuggestedQuestions(response.data.questions || []);
      } catch (error) {
        console.error('Error fetching suggested questions:', error);
        message.error('Failed to load suggested questions.');
      } finally {
        setLoadingSuggestions(false);
      }
    };

    // If the user hasn't sent a message and we have no messages in the chat => try to load suggestions
    if (!isFirstMessageSent && localMessages.length === 0) {
      fetchSuggestedQuestions();
    }
  }, [localMessages, isFirstMessageSent, currentChatDataSource]);

  // Handler for clicking on a suggested question
  const handleSuggestedQuestionClick = (question) => {
    handleSend(question);
  };

  // Send user input (or overrideText) to backend
  const handleSend = async (overrideText) => {
    const textToSend = overrideText || userInput;

    if (!currentChatDataSource) {
      message.error('Please select a data source before sending a message.');
      return;
    }

    if (textToSend.trim()) {
      const isNewChat = !currentChat;
      const newMessage = {
        from_user: 'user',
        text: textToSend,
        ...(isNewChat && { data_source_id: currentChatDataSource }),
      };

      setLocalMessages((prev) => [...prev, newMessage]); // Optimistic update
      setUserInput('');
      setLoading(true);

      try {
        const response = await updateChat(currentChat, newMessage, currentChatDataSource);
        if (response && response.data) {
          const gptMessage = {
            from_user: 'system',
            plainText: response.data.plain_text,
            commentary: response.data.commentary,
            messageId: response.data.message_id,
            data: response.data.data,
            sqlQuery: response.data.sql_query,
            chartConfig: response.data.chart_config,
            drillDownQuestions: response.data.drill_down_questions || [],
          };
          setLocalMessages((prev) => [...prev, gptMessage]);
        }
        setIsFirstMessageSent(true);
      } catch (error) {
        console.error('Error sending message:', error);
      } finally {
        setLoading(false);
      }
    }
  };


  // 1) Add a function to fetch filters from the server
  const fetchFilters = async (dataSourceId, sqlQuery, chartConfig) => {
    const payload = {
      data_source_id: dataSourceId,
      sql_query: sqlQuery,
      chart_config: chartConfig,
    };
    console.log("[fetchFilters] Sending payload:", payload);

    const response = await axios.post(
      `${API_BASE_URL}/generate-chart-filters/`,
      payload,
      { withCredentials: true }
    );
    return response.data; // e.g., { filters: [ { placeholder, query }, ... ] }
  };

  // 2) Update openNamingModal to fetch and insert filters into the chartConfig
  const openNamingModal = async (msg) => {
    if (!msg?.messageId) {
      console.error("Message ID is missing or undefined.", msg);
      return;
    }

    // Only fetch filters if we have a dataSource, an SQL query, and a chartConfig
    if (currentChatDataSource && msg.sqlQuery && msg.chartConfig) {
      try {
        // Call our helper to get filters
        const data = await fetchFilters(
          currentChatDataSource,
          msg.sqlQuery,
          msg.chartConfig
        );

        if (data && data.filters) {
          // Insert filters into the chartConfig
          msg.chartConfig = {
            ...msg.chartConfig,
            filters: data.filters, // <-- attach the filters
          };
          console.log("Filters added to chartConfig:", data.filters);
        }
      } catch (error) {
        console.error("Error fetching chart filters:", error);
        message.error("Failed to fetch filters.");
      }
    }

    // Now set the message as "savingMessage"
    setSavingMessage({ ...msg, messageId: msg.messageId });
    setIsNamingModalOpen(true);
  };
  const closeNamingModal = () => {
    setIsNamingModalOpen(false);
    setElementName('');
    setIsSuccess(false);
  };
  const handleSaveElement = async () => {
    if (!savingMessage?.messageId) {
      alert("Message ID is missing, please wait or try again.");
      console.error("Message ID is missing:", savingMessage);
      return;
    }
    const newElement = {
      element_type: 'graph',
      name: elementName,
      chart_config: savingMessage.chartConfig,
      message_id: savingMessage.messageId
    };
    try {
      console.log('New element payload:', newElement);
      await axios.post(`${API_BASE_URL}/save-element/`, newElement, {
        withCredentials: true,
      });
      message.success('Element saved successfully!');
      closeNamingModal();
    } catch (error) {
      console.error('Error saving element:', error.response?.data || error.message);
      message.error('Failed to save element. Please try again.');
    }
  };

  // Modal: viewing the raw data
  const openDataModal = (msg) => {
    const chartData = msg?.chartConfig?.data;
    // Check that chartData is an object and has the expected keys
    if (!chartData || typeof chartData !== 'object' || !chartData.labels || !chartData.datasets) {
      console.error("No data available or incorrect format:", msg);
      return;
    }

    // Look up the data source details (if available)
    const dsId = msg.databaseId;
    const dsObj = dsId ? databases[dsId] : null;
    const dsName = dsObj?.name || "Unknown DS";
    const dsType = dsObj?.type || "N/A";

    setViewingMessage({
      ...msg,
      data: chartData,
      dsName,
      dsType,
      sqlQuery: msg.sqlQuery,
    });
    setIsDataModalOpen(true);
  };

  const closeDataModal = () => {
    setIsDataModalOpen(false);
    setViewingMessage(null);
  };


  // Changing chart type locally & updating the backend
  const handleChartTypeChange = async (messageIndex, newFriendlyType) => {
    const msg = localMessages[messageIndex];
    if (!msg) {
      console.error("Message not found at index:", messageIndex);
      return;
    }

    // Determine the raw chart type and flags
    let rawType = '';
    let isCombo = false;
    switch (newFriendlyType) {
      case 'Table':
        rawType = 'table';
        break;
      case 'Bar':
        rawType = 'bar';
        break;
      case 'Line':
        rawType = 'line';
        break;
      case 'Area':
        // Treat as a line with fill enabled
        rawType = 'line';
        break;
      case 'Pie':
        rawType = 'pie';
        break;
      case 'Combo':
        rawType = 'bar';
        isCombo = true;
        break;
      default:
        rawType = 'bar';
    }

    // Update the message's chartConfig locally
    const updatedMessages = [...localMessages];
    const updatedMsg = { ...updatedMessages[messageIndex] };

    // Create a new chartConfig based on the new type
    let newChartConfig = { ...updatedMsg.chartConfig };

    if (rawType === 'table') {
      // For table, simply set the type to "table".
      newChartConfig.type = 'table';
      // Optionally preserve table-specific properties (columns, dataSource) if present.
    } else {
      // For other chart types, update the type and each dataset's type.
      newChartConfig.type = rawType;

      // IMPORTANT: Set the area flag when "Area" is selected.
      if (newFriendlyType === 'Area') {
        newChartConfig.isArea = true;
      } else {
        newChartConfig.isArea = false;
      }

      if (isCombo) {
        // For combo charts, first dataset is bar and others are line.
        newChartConfig.data.datasets = newChartConfig.data.datasets.map((ds, idx) => ({
          ...ds,
          type: idx === 0 ? 'bar' : 'line',
        }));
      } else {
        newChartConfig.data.datasets = newChartConfig.data.datasets.map((ds) => ({
          ...ds,
          type: rawType,
        }));
      }
    }

    // Update the message with the new chartConfig and selectedChartType.
    updatedMsg.chartConfig = newChartConfig;
    updatedMsg.selectedChartType = newFriendlyType;
    updatedMessages[messageIndex] = updatedMsg;
    setLocalMessages(updatedMessages);

    // Send update to backend if a valid messageId exists.
    if (msg.messageId) {
      try {
        await axios.patch(
          `${API_BASE_URL}/chats/${currentChat}/messages/${msg.messageId}/`,
          { chart_config: newChartConfig },
          { withCredentials: true }
        );
        console.log(`Chart type updated to "${newFriendlyType}" for message ID: ${msg.messageId}`);
      } catch (error) {
        console.error('Error updating chart type:', error.response?.data || error.message);
      }
    } else {
      console.warn("Message does not have a valid message ID:", msg);
    }
  };

  // The main function to render each message in the chat
  const renderMessage = (msg, index) => {
    // Decide user vs. system widths
    const isUser = msg.from_user === 'user';
    const cardMaxWidth = isUser ? '50%' : '70%';
    // If we have a chart
    if (msg.chartConfig) {
      const isChartLoading = chartLoadingStates[index] === true;

      return (
        <div
          key={index}
          style={{
            display: 'flex',
            justifyContent: msg.from_user === 'user' ? 'flex-end' : 'flex-start',
            marginBottom: '20px',
          }}
        >
          <Card
            style={{
              maxWidth: cardMaxWidth,
              backgroundColor: msg.from_user === 'user' ? '#f8f8f8' : '#ffffff',
              border: '1px solid #ccc',
              borderRadius: '10px',
            }}
            bodyStyle={{ padding: '10px' }}
          >
            {/* 1) Chart first */}
            <div
              style={{
                marginBottom: '30px',
                // ONLY force a 350px height if it's not table
                ...(
                  msg.selectedChartType?.toLowerCase() === 'table' ||
                  msg.chartConfig?.metadata?.chart?.type?.toLowerCase() === 'table'
                    ? {}
                    : { height: '350px' }
                ),
              }}
            >
              {isChartLoading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              ) : (
                <Visualization
                  config={msg.chartConfig}
                  requestedChartType={msg.selectedChartType}
                />
              )}
            </div>

            {/* 2) Then commentary or plain text below the chart */}
            <div style={{ marginBottom: '30px', whiteSpace: 'pre-wrap' }}>
              {msg.commentary && (
                <p style={{ marginBottom: '8px' }}>
                {msg.commentary}
                </p>
              )}
              {msg.plainText && (
                <p>
                  <strong>Answer:</strong> {msg.plainText}
                </p>
              )}
            </div>

            {/* 3) Buttons row (Save, View Data, Chart Type, Drill Down) */}
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button onClick={() => openNamingModal(msg)}>Save & Edit Chart</Button>
              <Button onClick={() => openDataModal(msg)}>View Data</Button>

              <Dropdown
                overlay={
                  <Menu>
                    {ALL_CHART_TYPES.map((chartType) => (
                      <Menu.Item key={chartType} onClick={() => handleChartTypeChange(index, chartType)}>
                        {chartType}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
              >
                <Button>
                  Chart Type <DownOutlined />
                </Button>
              </Dropdown>

              {/* Drill-down suggestions */}
              {msg.drillDownQuestions && msg.drillDownQuestions.length > 0 && (
                <Button
                  icon={<FilterOutlined />}
                  onClick={() =>
                    setExpandedDrillDown((prev) => ({
                      ...prev,
                      [index]: !prev[index],
                    }))
                  }
                >
                  Drill Down
                </Button>
              )}
            </div>

            {/* 4) Render the drill-down questions if expanded */}
            {expandedDrillDown[index] && msg.drillDownQuestions && msg.drillDownQuestions.length > 0 && (
              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',          // Use flex layout
                  flexDirection: 'column',  // Stack vertically
                  alignItems: 'flex-start', // Left-justify each item
                  gap: '8px'                // Some vertical spacing
                }}
              >
                {msg.drillDownQuestions.map((question, qIndex) => (
                  <Button
                    key={qIndex}
                    onClick={() => handleSend(question)}
                    style={{
                      width: 'auto'          // Only as wide as the button text
                    }}
                  >
                    {question}
                  </Button>
                ))}
              </div>
            )}
          </Card>
        </div>
      );
    }

    // If there's no chartConfig => a regular text message
    return (
      <div
        key={index}
        style={{
          display: 'flex',
          justifyContent: msg.from_user === 'user' ? 'flex-end' : 'flex-start',
          marginBottom: '20px',
        }}
      >
        <Card
          style={{
            maxWidth: cardMaxWidth,
            backgroundColor: msg.from_user === 'user' ? '#f8f8f8' : '#ffffff',
            border: '1px solid #ccc',
            borderRadius: '10px',
          }}
          bodyStyle={{ whiteSpace: 'pre-wrap', padding: '10px' }}
        >
          {/* If there's commentary, show it. Otherwise, plainText or fallback to msg.text */}
          {msg.commentary ? (
            <p>
              <strong>Commentary:</strong> {msg.commentary}
            </p>
          ) : msg.plainText ? (
            msg.plainText
          ) : msg.text ? (
            msg.text
          ) : (
            'No content available.'
          )}
        </Card>
      </div>
    );
  };

  return (
    <div className="chat-interface">
      {/* Chat window */}
      <div className="chat-window" ref={chatWindowRef}>
        {currentChat && localMessages.length > 0 ? (
          <>
            {localMessages.map((msg, index) => renderMessage(msg, index))}
            {loading && (
              <div className="chat-message gpt-message loading">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </div>
            )}
          </>
        ) : (
          // If no messages, show “suggested questions” or a spinner
          <div className="suggested-questions-container">
            <h3
              style={{
                textAlign: 'center',
                marginBottom: '20px',
                marginTop: '90px',
                fontSize: '20px',
              }}
            >
              Ask Spruce about your data
            </h3>
            <Row gutter={[16, 16]} justify="center">
              {loadingSuggestions ? (
                <Spin size="large" style={{ marginTop: 40, marginBottom: '20px' }} />
              ) : (
                suggestedQuestions.map((question, index) => (
                  <Col key={index} span={10}>
                    <Card
                      hoverable
                      style={{
                        border: '1px solid #d9d9d9',
                        textAlign: 'center',
                        cursor: 'pointer',
                        height: '80px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={() => handleSuggestedQuestionClick(question)}
                    >
                      {question}
                    </Card>
                  </Col>
                ))
              )}
            </Row>

            {/* Data source selector */}
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Dropdown
                overlay={
                  <Menu>
                    {Object.entries(databases).map(([id, name]) => (
                      <Menu.Item
                        key={id}
                        onClick={() => setCurrentChatDataSource(id)}
                      >
                        {name}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
              >
                <Button
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    padding: '10px 20px',
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                >
                  <span style={{ color: 'black' }}>
                    <DatabaseOutlined style={{ color: 'black', marginRight: '10px' }} />
                    {databases[currentChatDataSource] || 'Select Data Source'}
                  </span>
                  <DownOutlined style={{ marginLeft: '8px', color: 'black' }} />
                </Button>
              </Dropdown>
            </div>
          </div>
        )}
      </div>

      {/* Input bar & DS indicator */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '10px',
          marginLeft: '10px',
          marginRight: '10px',
          position: 'relative',
        }}
      >
        {/* Show DS name in top-right corner once a message is sent */}
        {isFirstMessageSent && currentChatDataSource && (
          <div
            style={{
              position: 'absolute',
              top: '-40px',
              right: '0px',
              maxWidth: 'calc(100% - 40px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              color: '#b5b5b5',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              border: '1px solid #ccc',
              borderRadius: '10px',
              backgroundColor: '#fff',
              padding: '5px 10px',
            }}
          >
            <DatabaseOutlined style={{ marginRight: '8px', color: '#b5b5b5' }} />
            <span title={databases[currentChatDataSource]}>
              {databases[currentChatDataSource]}
            </span>
          </div>
        )}

        {/* Message input */}
        <Input
          placeholder="Type your message..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onPressEnter={(e) => {
            if (!e.shiftKey) {
              handleSend();
              e.preventDefault();
            }
          }}
          suffix={
            <SendOutlined
              onClick={handleSend}
              style={{ fontSize: '18px', cursor: 'pointer', color: '#1677ff' }}
            />
          }
          style={{ borderRadius: '10px' }}
        />
      </div>

      {/* Naming modal (for “Save as Element”) */}
      <Modal
        title="Name Your Element"
        visible={isNamingModalOpen}
        onOk={handleSaveElement}
        onCancel={closeNamingModal}
      >
        <Input
          placeholder="Element Name"
          value={elementName}
          onChange={(e) => setElementName(e.target.value)}
        />
      </Modal>

      {/* Data preview modal */}
      <Modal
        visible={isDataModalOpen}
        footer={null}
        onCancel={closeDataModal}
        width={800}
        bodyStyle={{ padding: '20px' }}
      >
        {viewingMessage && viewingMessage.sqlQuery && (
          <p style={{ marginBottom: '16px' }}>
            <strong>SQL Query:</strong>
            <br />
            <br />
            {viewingMessage.sqlQuery}
          </p>
        )}

        {viewingMessage && viewingMessage.chartConfig?.data ? (
          <>
            <p>
              <strong>Query Results:</strong>
            </p>
            <div style={{ marginTop: '16px' }}>
              <TableVisualization config={viewingMessage.chartConfig} />
            </div>
          </>
        ) : (
          <p>No data available.</p>
        )}
      </Modal>
    </div>
  );
};

export default ChatInterface;
