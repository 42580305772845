import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../fetchWithCsrf';
import Visualization from '../components/Visualization';
import { v4 as uuidv4 } from 'uuid';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PptxGenJS from 'pptxgenjs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';

import {
  Button,
  Modal,
  Input,
  Select,
  message,
  Typography,
  Card,
  Row,
  Col,
  Tooltip,
  Empty,
  Menu,
  Dropdown,
  Checkbox,
  Form,
  Switch,
  InputNumber,
  ColorPicker,
} from 'antd';
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  AppstoreOutlined,
  LoadingOutlined,
  ClearOutlined
} from '@ant-design/icons';

import './Home.css';
import './Charts.css';
import { WidthProvider, Responsive } from 'react-grid-layout';
import EmptyDashboardPlaceholder from '../components/EmptyDashboardPlaceholder';
import { hexToRGBA } from '../components/hexToRGBA'; // adjust the path as needed
import UnsavedChangesPrompt from '../components/UnsavedChangesPrompt';  // Re-use if you want in-app navigation warnings
import TableVisualization from '../components/TableVisualization';      // If you have a table-based component
import API_BASE_URL from "../config";


const { Title, Text } = Typography;
const ResponsiveGridLayout = WidthProvider(Responsive);

const Home = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // ----------------------------------------------
  // Dashboard State
  // ----------------------------------------------
  const [dashboard, setDashboard] = useState({
    id: null,
    name: 'New Dashboard',
    height: 1000,
    elements: [],
  });
  const [availableElements, setAvailableElements] = useState([]);
  const [savedDashboards, setDashboards] = useState([]);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [setChangesSaved] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [navigationTarget] = useState(null);

  // Currently selected element (for the right sidebar “edit”)
  const [selectedElement, setSelectedElement] = useState(null);

  // Show/hide the right sidebar
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // For color picking, let user select which dataset
  const [datasetIndex, setDatasetIndex] = useState(0);

  // For “Add Chart” tab in the sidebar
  const [currentTab, setCurrentTab] = useState('element');
  const [searchQuery, setSearchQuery] = useState('');

  // Dashboard pickers, rename, etc.
  const [isDashboardsModalOpen, setIsDashboardsModalOpen] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [actionModalType, setActionModalType] = useState('');
  const [selectedDashboardItem, setSelectedDashboardItem] = useState(null);
  const [newName, setNewName] = useState('');

  const [isDashboardNameModalOpen, setIsDashboardNameModalOpen] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState('');

  // For scheduling
  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [repeatOption, setRepeatOption] = useState('none');
  const [timeInput, setTimeInput] = useState('');
  const [timeError, setTimeError] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Data sources & templates
  const [dataSources, setDataSources] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [isDataSourceModalOpen, setDataSourceModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // If user picks “Create Custom” from the new placeholder
  const [choseCustom, setChoseCustom] = useState(false);

  // For the second y-axis label logic
  const [secondYAxisLabel, setSecondYAxisLabel] = useState('');

  // Draggable/resizable
  const [isEditing, setIsEditing] = useState(false);

  // For Data modal (View Data)
  const [isDataModalOpen, setIsDataModalOpen] = useState(false);

  // Layout breakpoints
  const breakpoints = { lg: 1200, md: 1000, sm: 768, xs: 480, xxs: 0 };
  const cols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 };

  // ----------------------------------------------
  //  Chat-based Reconfigure for the selected element
  // ----------------------------------------------
  const [setUserQuery] = useState('');
  const [setReconfiguredMessage] = useState(null);

  // On mount, see if we have an ID in the URL
  useEffect(() => {
    if (!id) {
      axios
        .get(`${API_BASE_URL}/user-profile/`, { withCredentials: true })
        .then((response) => {
          const lastId = response.data.last_used_dashboard_id;
          if (lastId) {
            navigate(`/home/${lastId}`);
          }
        })
        .catch((err) => console.error('Error fetching user profile:', err));
    }
  }, [id, navigate]);

  // Fetch the actual dashboard data
  const fetchDashboard = useCallback(async () => {
    try {
      const resp = await axios.get(`${API_BASE_URL}/get-dashboard/${id}/`, {
        withCredentials: true,
      });
      const dashboardData = resp.data;
      // Build array of elements with guaranteed unique tempIds
      const updatedElements = (dashboardData.elements || []).map((item) => ({
        ...item,
        dashboard_element_id: item.dashboard_element_id,
        element_id: item.element_id,
        chart_config: item.chart_config || {},
        type: item.type,
        content: item.content,
        fontSize: item.fontSize || 16,
        isBold: item.isBold || false,
        isItalic: item.isItalic || false,
        hasBorder: item.hasBorder || false,
        x: item.x || 0,
        y: item.y || 0,
        w: item.w || 4,
        h: item.h || 6,
        tempId: item.dashboard_element_id ? null : uuidv4(),
        sql_query: item.sql_query || null,
      }));

      setDashboard({
        id: dashboardData.id,
        name: dashboardData.name,
        height: dashboardData.height || 1000,
        elements: updatedElements,
      });
    } catch (error) {
      console.error('Error fetching dashboard:', error);
    }
  }, [id]);

  // Fetch the base elements + the selected dashboard
  useEffect(() => {
    if (id) fetchDashboard();
    axios
      .get(`${API_BASE_URL}/get-elements/`, { withCredentials: true })
      .then((res) => setAvailableElements(res.data))
      .catch((err) => console.error('Error fetching elements:', err));
  }, [fetchDashboard, id]);

  // Warn if leaving the page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  // Load dashboards
  useEffect(() => {
    const loadAllDashboards = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/get-dashboards/`, {
          withCredentials: true,
        });
        setDashboards(response.data || []);
      } catch (error) {
        console.error('Error fetching dashboards:', error);
      }
    };
    loadAllDashboards();
  }, []);

  // Also fetch data sources
  useEffect(() => {
    fetchDataSources();
  }, []);

  const fetchDataSources = async () => {
    try {
      const resp = await axios.get(`${API_BASE_URL}/datasources/`, {
        withCredentials: true,
      });
      setDataSources(resp.data || []);
    } catch (err) {
      console.error('Error fetching data sources:', err);
    }
  };

  // Open “Name your new dashboard” modal
  const openDashboardNameModal = () => {
    setNewDashboardName('');
    setIsDashboardNameModalOpen(true);
  };
  const closeDashboardNameModal = () => {
    setIsDashboardNameModalOpen(false);
  };

  // Create new blank dashboard
  const handleCreateNewDashboard = async () => {
    const name = newDashboardName.trim();
    if (!name) {
      message.error('Please enter a name for your dashboard.');
      return;
    }
    try {
      const response = await axios.post(
        `${API_BASE_URL}/create-new-dashboard/`,
        { name },
        { withCredentials: true }
      );
      setDashboards((prev) => [...prev, response.data]);
      setIsDashboardNameModalOpen(false);
      navigate(`/home/${response.data.id}`);
    } catch (error) {
      console.error('Error creating new dashboard:', error);
      message.error('Failed to create dashboard.');
    }
  };

  // Templates
  const dashboardTemplates = [
    {
      id: 'template-1',
      name: 'Business Overview',
      url: '/api/generate-business-overview/',
      description: 'High-level KPIs, revenue, growth metrics, etc.',
    },
    {
      id: 'template-2',
      name: 'Sales Analysis',
      url: '/api/generate-sales-analysis/',
      description: 'Campaign performance, CTR, CPC, channel ROI.',
    },
    {
      id: 'template-3',
      name: 'Inventory Analysis',
      url: '/api/generate-inventory-analysis/',
      description: 'Top products, sales volume, conversion, etc.',
    },
    {
      id: 'template-4',
      name: 'Promotion Analysis',
      url: '/api/generate-promotion-analysis/',
      description: 'Top products, sales volume, conversion, etc.',
    },
  ];
  const handleSelectDataSourceModal = (template) => {
    setSelectedTemplate(template);
    setDataSourceModalOpen(true);
  };
  const handleSelectTemplate = async (template, dataSourceId) => {
    if (!dataSourceId) {
      message.error('Please select a data source before creating a dashboard.');
      return;
    }
    try {
      const response = await axios.post(
        `${API_BASE_URL}${template.url}`,
        {
          name: template.name,
          data_source_id: dataSourceId,
        },
        { withCredentials: true }
      );
      setDashboards((prev) => [...prev, response.data]);
      navigate(`/home/${response.data.id}`);
      message.success(`Dashboard created successfully from the ${template.name} template.`);
      setDataSourceModalOpen(false);
      setSelectedDataSource(null);
    } catch (error) {
      console.error('Error creating dashboard from template:', error);
      message.error(`Failed to create dashboard from ${template.name}.`);
    }
  };

  // Mark which chart type is selected in an element
  const mapConfigToChartType = (cfg) => {
    if (!cfg?.type) return '';
    const chartType = cfg.type.toLowerCase();

    // Check for combos
    if (cfg.combo) return 'Combo';
    if (cfg.isArea) return 'Area';

    // auto detect combos if multiple dataset types
    if (cfg.data && Array.isArray(cfg.data.datasets)) {
      const datasetTypes = new Set(
        cfg.data.datasets.map((d) => (d.type ? d.type.toLowerCase() : chartType))
      );
      if (datasetTypes.has('bar') && datasetTypes.has('line')) return 'Combo';
    }

    if (chartType === 'line') return 'Line';
    if (chartType === 'bar') return 'Bar';
    if (chartType === 'pie') return 'Pie';
    if (chartType === 'table') return 'Table';

    return chartType.charAt(0).toUpperCase() + chartType.slice(1);
  };

  // Save the entire dashboard to server
  const handleSaveDashboard = () => {
    const elementsToSave = dashboard.elements.map((el) => ({
      dashboard_element_id: el.dashboard_element_id,
      element_id: el.element_id || null,
      chart_config: el.chart_config,
      type: el.type,
      content: el.content,
      x: el.x,
      y: el.y,
      w: el.w,
      h: el.h,
      fontSize: el.fontSize,
      isBold: el.isBold || false,
      isItalic: el.isItalic || false,
      hasBorder: el.hasBorder || false,
    }));

    axios
      .post(
        `${API_BASE_URL}/save-dashboard/`,
        {
          id: dashboard.id,
          name: dashboard.name,
          height: dashboard.height,
          elements: elementsToSave,
        },
        { withCredentials: true }
      )
      .then((response) => {
        const serverData = response.data;
        setDashboard((prev) => {
          const updatedEls = prev.elements.map((localEl) => {
            const match = serverData.elements.find(
              (sEl) => sEl.dashboard_element_id === localEl.dashboard_element_id
            );
            if (!match) return localEl;
            return {
              ...localEl,
              dashboard_element_id: match.dashboard_element_id,
              element_id: match.element_id,
            };
          });
          return {
            ...prev,
            id: serverData.id,
            name: serverData.name,
            height: serverData.height,
            elements: updatedEls,
          };
        });

        setSelectedElement(null);
        setHasUnsavedChanges(false);
        setChangesSaved(true);
        message.success('Dashboard saved successfully');
        setTimeout(() => setChangesSaved(false), 4000);

        if (navigationTarget) {
          navigate(navigationTarget);
        }
      })
      .catch((error) => {
        console.error('Error saving dashboard:', error);
        message.error('An error occurred while saving the dashboard.');
      });
  };

  const handleExitWithoutSaving = () => {
    setIsModalOpen(false);
    setHasUnsavedChanges(false);
    if (navigationTarget) navigate(navigationTarget);
  };

  // Pick a dashboard
  const handleSelectDashboard = (dashboardId) => {
    axios
      .post(
        `${API_BASE_URL}/update-last-dashboard/`,
        { dashboard_id: dashboardId },
        { withCredentials: true }
      )
      .then(() => navigate(`/home/${dashboardId}`))
      .catch((error) => console.error('Error updating last used dashboard:', error));
  };

  // When user clicks an element on the grid
  const handleElementClick = (element) => {
    setSelectedElement(element);
    setSidebarOpen(true);

    // If we want to unify the “chat” states each time we pick an element,
    // we can reset them or store them in the element itself. For simplicity:
    setUserQuery('');
    setReconfiguredMessage(null);

    // Mark that we are in “edit” mode
    setIsEditing(true);
  };

  // Helper to get a safe chart_config object
  const getSafeChartConfig = (el) => {
    const newConfig = JSON.parse(JSON.stringify(el.chart_config || {}));
    if (!newConfig.options) newConfig.options = {};
    if (!newConfig.options.scales) newConfig.options.scales = {};
    if (!newConfig.options.plugins) newConfig.options.plugins = {};
    if (!newConfig.data) newConfig.data = { labels: [], datasets: [] };
    return newConfig;
  };

  // Update the selectedElement’s chart_config inside the dashboard state
  const updateElementInDashboard = (updatedElement) => {
    setDashboard((prev) => {
      const newEls = prev.elements.map((el) => {
        const matchByRealId =
          el.dashboard_element_id && updatedElement.dashboard_element_id
            ? el.dashboard_element_id === updatedElement.dashboard_element_id
            : false;
        const matchByTempId =
          el.tempId && updatedElement.tempId ? el.tempId === updatedElement.tempId : false;

        if (matchByRealId || matchByTempId) {
          return updatedElement;
        }
        return el;
      });
      return { ...prev, elements: newEls };
    });
    setSelectedElement(updatedElement);
    setHasUnsavedChanges(true);
  };

  // Delete an element
  const handleDeleteElement = async () => {
    if (!selectedElement) return;

    // If it's not saved yet, just remove from state
    if (!selectedElement.dashboard_element_id && selectedElement.tempId) {
      setDashboard((prev) => ({
        ...prev,
        elements: prev.elements.filter((el) => el.tempId !== selectedElement.tempId),
      }));
      setSelectedElement(null);
      setHasUnsavedChanges(true);
      return;
    }

    // If it has a real ID, hit the server
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/delete-dashboard-element/${dashboard.id}/${selectedElement.element_id}/`,
        { withCredentials: true }
      );
      if (response.status === 204) {
        setDashboard((prev) => ({
          ...prev,
          elements: prev.elements.filter(
            (el) => el.dashboard_element_id !== selectedElement.dashboard_element_id
          ),
        }));
        setSelectedElement(null);
        setHasUnsavedChanges(true);
      }
    } catch (error) {
      console.error('Error deleting element:', error);
      // If not found on server, still remove from local
      setDashboard((prev) => ({
        ...prev,
        elements: prev.elements.filter(
          (el) => el.dashboard_element_id !== selectedElement.dashboard_element_id
        ),
      }));
      setSelectedElement(null);
      setIsEditing(false);
      setHasUnsavedChanges(true);
    }
  };

  // Basic text style changes
  const handleTextStyleChange = (dashboardElementId, styleProperty, value) => {
    setDashboard((prev) => {
      const newEls = prev.elements.map((el) => {
        const matchId = el.dashboard_element_id || el.tempId?.toString() || null;
        if (matchId && matchId === dashboardElementId) {
          return { ...el, [styleProperty]: value };
        }
        return el;
      });
      return { ...prev, elements: newEls };
    });
    if (
      selectedElement &&
      (selectedElement.dashboard_element_id === dashboardElementId ||
        selectedElement.tempId === dashboardElementId)
    ) {
      setSelectedElement((old) => ({ ...old, [styleProperty]: value }));
    }
    setHasUnsavedChanges(true);
  };
  const handleTextEdit = (updatedText, dashboardElementId) => {
    setDashboard((prev) => {
      const newEls = prev.elements.map((el) => {
        const matchId = el.dashboard_element_id || el.tempId?.toString() || null;
        if (matchId && matchId === dashboardElementId) {
          return { ...el, content: updatedText };
        }
        return el;
      });
      return { ...prev, elements: newEls };
    });
    setHasUnsavedChanges(true);
  };

  // Add new chart from the “Add Chart” list
  const handleAddChartClick = () => {
    setSidebarOpen(true);
    setCurrentTab('element');
  };
  const handleAddElement = (baseElement) => {
    const newItem = {
      dashboard_element_id: null,
      element_id: null,
      chart_config: JSON.parse(JSON.stringify(baseElement.chart_config)) || {},
      type: baseElement.type || 'chart',
      name: baseElement.name,
      content: baseElement.content || '',
      fontSize: baseElement.fontSize || 16,
      isBold: baseElement.isBold || false,
      isItalic: baseElement.isItalic || false,
      hasBorder: baseElement.has_border || false,
      x: 0,
      y: dashboard.elements.length,
      w: 8,
      h: 10,
      tempId: uuidv4(),
      sql_query: baseElement.sql_query || null,
    };
    newItem.selectedChartType = mapConfigToChartType(newItem.chart_config);

    setDashboard((prev) => ({ ...prev, elements: [...prev.elements, newItem] }));
    setSelectedElement(newItem);
    setIsEditing(true);
    setSidebarOpen(true);
  };
  const handleAddText = (textType, fontSize) => {
    const newTextItem = {
      dashboard_element_id: null,
      element_id: null,
      chart_config: {},
      type: 'text',
      content: textType,
      fontSize,
      isBold: false,
      isItalic: false,
      hasBorder: true,
      x: 0,
      y: dashboard.elements.length,
      w: 4,
      h: 3,
      tempId: uuidv4(),
    };
    setDashboard((prev) => ({ ...prev, elements: [...prev.elements, newTextItem] }));
    setSelectedElement(newTextItem);
    setSidebarOpen(true);
  };

  // Grid layout changes => store x,y,w,h
  const handleLayoutChange = (currentLayout) => {
    setDashboard((prev) => {
      const updatedElements = prev.elements.map((el) => {
        const elementUniqueId = el.dashboard_element_id
          ? `db_${el.dashboard_element_id}`
          : `temp_${el.tempId}`;
        const found = currentLayout.find((item) => item.i === elementUniqueId);
        if (found) {
          return { ...el, x: found.x, y: found.y, w: found.w, h: found.h };
        }
        return el;
      });
      return { ...prev, elements: updatedElements };
    });

    // Make sure to update the currently selected element’s size
    if (selectedElement) {
      const elementUniqueId = selectedElement.dashboard_element_id
        ? `db_${selectedElement.dashboard_element_id}`
        : `temp_${selectedElement.tempId}`;
      const found = currentLayout.find((item) => item.i === elementUniqueId);
      if (found) {
        setSelectedElement((old) => ({ ...old, x: found.x, y: found.y, w: found.w, h: found.h }));
      }
    }
    setHasUnsavedChanges(true);
  };
  const layout = dashboard.elements.map((el) => {
    const elementUniqueId = el.dashboard_element_id
      ? `db_${el.dashboard_element_id}`
      : `temp_${el.tempId}`;
    return {
      i: elementUniqueId,
      x: el.x ?? 0,
      y: el.y ?? 0,
      w: el.w ?? 4,
      h: el.h ?? 6,
      minW: 2,
      minH: 2,
    };
  });
  const layouts = {
    lg: layout,
    md: layout,
    sm: layout,
    xs: layout,
    xxs: layout,
  };

  // Refresh / schedule
  const handleRefreshNow = async () => {
    setIsRefreshing(true);
    try {
      await axios.post(
        `${API_BASE_URL}/refresh-dashboard-now/${id}/`,
        null,
        { withCredentials: true }
      );
      await fetchDashboard();
      message.success('Dashboard refreshed successfully');
    } catch (error) {
      console.error('Error refreshing dashboard:', error);
      message.error('Failed to refresh dashboard.');
    } finally {
      setIsRefreshing(false);
    }
  };
  const handleDaySelection = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day) ? prevDays.filter((d) => d !== day) : [...prevDays, day]
    );
  };
  const validateTimeInput = (time) => {
    const timeFormat = /^([1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/i;
    return timeFormat.test(time);
  };
  const handleScheduleRefresh = () => {
    if (!validateTimeInput(timeInput)) {
      setTimeError('Please enter a valid time in HH:MM AM/PM format.');
      return;
    }
    setTimeError('');
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const scheduleData = { selectedDays, timeInput, repeatOption, timeZone };

    axios
      .post(
        `${API_BASE_URL}/set-dashboard-refresh-schedule/${id}/`,
        { scheduleData },
        { withCredentials: true }
      )
      .then(() => {
        message.success('Schedule set successfully');
        setScheduleModalOpen(false);
      })
      .catch((error) => {
        console.error('Error setting schedule:', error);
        message.error('Failed to set schedule.');
      });
  };

  // Share menu items
  const handleCopyEmailLink = (dashboardId) => {
    axios
      .post(`${API_BASE_URL}/generate-shareable-link/`, { dashboard_id: dashboardId })
      .then((response) => {
        const { link } = response.data;
        axios
          .post(`${API_BASE_URL}/handle-modal-preference/`, {
            update_preference: false,
          })
          .then((res) => {
            const { show_modal } = res.data;
            let updatePreference = false;
            if (show_modal) {
              Modal.info({
                title: 'Manage Dashboard Access',
                content: (
                  <div>
                    <p>You can manage user access to this dashboard in settings.</p>
                    <Checkbox
                      onChange={(e) => {
                        updatePreference = e.target.checked;
                      }}
                    >
                      Do not show this alert again
                    </Checkbox>
                  </div>
                ),
                onOk: () => {
                  if (updatePreference) {
                    axios
                      .post(`${API_BASE_URL}/handle-modal-preference/`, {
                        update_preference: true,
                      })
                      .catch((err) => console.error('Error updating preference:', err));
                  }
                  navigator.clipboard
                    .writeText(link)
                    .then(() => {
                      message.success('Link copied to clipboard!');
                    })
                    .catch((err) => console.error('Failed to copy link:', err));
                },
              });
            } else {
              navigator.clipboard
                .writeText(link)
                .then(() => {
                  message.success('Link copied to clipboard!');
                })
                .catch((err) => console.error('Failed to copy link:', err));
            }
          })
          .catch((err) => console.error('Error checking preference:', err));
      })
      .catch((err) => console.error('Error generating shareable link:', err));
  };
  // Export logic
  const handleExportToPDF = async () => {
    const dashEl = document.getElementById('dashboard-container');
    if (!dashEl) return;
    try {
      const canvas = await html2canvas(dashEl, { useCORS: true, scale: 3 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('l', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      if (pdfHeight > pdf.internal.pageSize.getHeight()) {
        const scaleFactor = pdf.internal.pageSize.getHeight() / pdfHeight;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth * scaleFactor, pdfHeight * scaleFactor);
      } else {
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      }
      pdf.save(`${dashboard.name}.pdf`);
    } catch (error) {
      console.error('Error exporting dashboard to PDF:', error);
    }
  };
  const handleExportToPPTX = async () => {
    const dashEl = document.getElementById('dashboard-container');
    if (!dashEl) return;
    try {
      const canvas = await html2canvas(dashEl, { useCORS: true, scale: 3 });
      const imgData = canvas.toDataURL('image/png');
      const pptx = new PptxGenJS();
      const slide = pptx.addSlide();
      const slideWidth = 10;
      const slideHeight = 5.625;
      const imgWidth = slideWidth;
      const imgHeight = (canvas.height * slideWidth) / canvas.width;
      if (imgHeight > slideHeight) {
        const scaleFactor = slideHeight / imgHeight;
        slide.addImage({
          data: imgData,
          x: 0.5,
          y: 0.5,
          w: imgWidth * scaleFactor,
          h: imgHeight * scaleFactor,
        });
      } else {
        slide.addImage({
          data: imgData,
          x: 0.5,
          y: 0.5,
          w: imgWidth,
          h: imgHeight,
        });
      }
      pptx.writeFile({ fileName: `${dashboard.name}.pptx` });
    } catch (error) {
      console.error('Error exporting dashboard to PowerPoint:', error);
    }
  };
  const handleExportToCSV = () => {
    const zip = new JSZip();
    dashboard.elements.forEach((el, idx) => {
      if (el.data) {
        const rows = [
          Object.keys(el.data[0]),
          ...el.data.map((row) => Object.values(row)),
        ];
        const csvContent = rows.map((r) => r.join(',')).join('\n');
        zip.file(`element-${idx + 1}.csv`, csvContent);
      }
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${dashboard.name}.zip`);
    });
  };
  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    dashboard.elements.forEach((el, idx) => {
      if (el.data) {
        const ws = XLSX.utils.json_to_sheet(el.data);
        XLSX.utils.book_append_sheet(workbook, ws, `Element-${idx + 1}`);
      }
    });
    XLSX.writeFile(workbook, `${dashboard.name}.xlsx`);
  };
  const refreshMenu = (
    <Menu>
      <Menu.Item key="refresh-now" onClick={handleRefreshNow}>
        Refresh now
      </Menu.Item>
      <Menu.Item key="schedule-refresh" onClick={() => setScheduleModalOpen(true)}>
        Schedule refresh
      </Menu.Item>
    </Menu>
  );
  const shareMenu = (
    <Menu>
      <Menu.Item key="copy-email-link" onClick={() => handleCopyEmailLink(dashboard.id)}>
        Copy Email Link
      </Menu.Item>
      <Menu.Item key="export-pdf" onClick={handleExportToPDF}>
        Export to PDF
      </Menu.Item>
      <Menu.Item key="export-pptx" onClick={handleExportToPPTX}>
        Export to PowerPoint
      </Menu.Item>
      <Menu.Item key="export-excel" onClick={handleExportToExcel}>
        Export Data to Excel
      </Menu.Item>
      <Menu.Item key="export-csv" onClick={handleExportToCSV}>
        Export Data to CSV
      </Menu.Item>
    </Menu>
  );

  // Dashboard rename/delete/duplicate
  const renderDashboardCardActions = (db) => [
    <Tooltip title="Rename" key="edit">
      <EditOutlined
        onClick={(e) => {
          e.stopPropagation();
          openRenameAction(db);
        }}
      />
    </Tooltip>,
    <Tooltip title="Duplicate" key="duplicate">
      <CopyOutlined
        onClick={(e) => {
          e.stopPropagation();
          handleDuplicate(db);
        }}
      />
    </Tooltip>,
    <Tooltip title="Delete" key="delete">
      <DeleteOutlined
        onClick={(e) => {
          e.stopPropagation();
          openDeleteAction(db);
        }}
      />
    </Tooltip>,
  ];
  const openDashboardModal = () => {
    setIsDashboardsModalOpen(true);
  };
  const closeDashboardModal = () => {
    setIsDashboardsModalOpen(false);
    setSelectedDashboardItem(null);
    setNewName('');
  };
  const openRenameAction = (dashboardItem) => {
    setActionModalType('rename');
    setSelectedDashboardItem(dashboardItem);
    setNewName(dashboardItem.name);
    setIsActionModalOpen(true);
  };
  const openDeleteAction = (dashboardItem) => {
    setActionModalType('delete');
    setSelectedDashboardItem(dashboardItem);
    setIsActionModalOpen(true);
  };
  const closeActionModal = () => {
    setIsActionModalOpen(false);
    setActionModalType('');
    setSelectedDashboardItem(null);
    setNewName('');
  };
  const handleRename = async () => {
    if (!selectedDashboardItem) return;
    try {
      await axios.patch(
        `${API_BASE_URL}/rename-dashboard/${selectedDashboardItem.id}/`,
        { name: newName },
        { withCredentials: true }
      );
      setDashboards((prev) =>
        prev.map((db) => (db.id === selectedDashboardItem.id ? { ...db, name: newName } : db))
      );
      message.success('Dashboard renamed successfully!');
    } catch (error) {
      console.error('Error renaming dashboard:', error);
      message.error('Failed to rename dashboard.');
    }
    closeActionModal();
  };
  const handleDelete = async () => {
    if (!selectedDashboardItem) return;
    try {
      await axios.delete(
        `${API_BASE_URL}/delete-dashboard/${selectedDashboardItem.id}/`,
        { withCredentials: true }
      );
      setDashboards((prev) => prev.filter((db) => db.id !== selectedDashboardItem.id));
      message.success('Dashboard deleted successfully!');
    } catch (error) {
      console.error('Error deleting dashboard:', error);
      message.error('Failed to delete dashboard.');
    }
    closeActionModal();
  };
  const handleDuplicate = async (dashboardItem) => {
    try {
      const resp = await axios.post(
        `${API_BASE_URL}/duplicate-dashboard/${dashboardItem.id}/`,
        {},
        { withCredentials: true }
      );
      setDashboards((prev) => [...prev, resp.data.new_dashboard]);
      message.success('Dashboard duplicated successfully!');
    } catch (error) {
      console.error('Error duplicating dashboard:', error);
      message.error('Failed to duplicate dashboard.');
    }
  };


  // ----------------------------------------------
  //  RENDER: Chart Editing UI (mirroring EditElement)
  // ----------------------------------------------
  const renderDynamicControls = (chartEl) => {
    if (!chartEl) return null;

    const cfg = chartEl.chart_config || {};
    const friendlyChartType = mapConfigToChartType(cfg);
    const legendDisplay = cfg.options?.plugins?.legend?.display ?? true;
    const chartTitle = cfg.options?.plugins?.title?.text || '';
    const xAxisLabel = cfg.options?.scales?.x?.title?.text || '';
    const yAxisLabel = cfg.options?.scales?.y?.title?.text || '';
    const showDataLabels = cfg.showDataLabels ?? false;
    const dataLabelOffset = cfg.dataLabelOffset ?? 0;

    // Does it have multiple y-axis?
    const uniqueYAxisIDs = new Set(
      (cfg.data?.datasets || []).map((ds) => ds.yAxisID || 'y')
    );
    const hasSecondAxis = uniqueYAxisIDs.size > 1;



    // Chart type changes
    // In your renderDynamicControls or similar function:
    const handleChartTypeChange = (newFriendlyType) => {
      let isComboType = false;
      let newRawType = '';

      switch (newFriendlyType) {
        case 'Table':
          newRawType = 'table';
          break;
        case 'Bar':
          newRawType = 'bar';
          break;
        case 'Line':
          newRawType = 'line';
          break;
        case 'Area':
          // Area is treated as line with fill enabled.
          newRawType = 'line';
          break;
        case 'Pie':
          newRawType = 'pie';
          break;
        case 'Combo':
          newRawType = 'bar';
          isComboType = true;
          break;
        default:
          newRawType = 'bar';
          break;
      }

      // Make a safe copy of the current chart_config from the selected element.
      const safeCfg = getSafeChartConfig(selectedElement);

      if (newRawType === 'pie') {
        // For pie charts, stash scales and force type to 'pie'
        if (!safeCfg._old_scales) {
          safeCfg._old_scales = safeCfg.options.scales;
        }
        safeCfg.options.scales = {};
        safeCfg.type = 'pie';
        safeCfg.data.datasets = safeCfg.data.datasets.map((ds) => ({
          ...ds,
          type: 'pie',
        }));
        safeCfg.combo = false;
        safeCfg.isArea = false;
      } else {
        // Restore old scales if available; otherwise ensure scales exists.
        if (safeCfg._old_scales) {
          safeCfg.options.scales = safeCfg._old_scales;
          delete safeCfg._old_scales;
        } else {
          safeCfg.options.scales = safeCfg.options.scales || {};
        }

        safeCfg.type = newRawType;
        safeCfg.isArea = (newFriendlyType === 'Area');
        safeCfg.combo = isComboType;

        // Update each dataset:
        safeCfg.data.datasets = safeCfg.data.datasets.map((ds, idx) => {
          // For combo mode, assign type based on index; otherwise use newRawType.
          let updatedDs = isComboType
            ? { ...ds, type: idx === 0 ? 'bar' : 'line' }
            : { ...ds, type: newRawType };

          // If a custom color was picked (stored in _customColor), recalc colors.
          if (ds._customColor) {
            if (newFriendlyType === 'Area') {
              updatedDs = {
                ...updatedDs,
                borderColor: ds._customColor,
                backgroundColor: hexToRGBA(ds._customColor, 0.3),
                pointBackgroundColor: ds._customColor,
                pointBorderColor: ds._customColor,
              };
            } else {
              updatedDs = {
                ...updatedDs,
                borderColor: ds._customColor,
                backgroundColor: ds._customColor,
                pointBackgroundColor: ds._customColor,
                pointBorderColor: ds._customColor,
              };
            }
          }
          return updatedDs;
        });
      }

      // Now update the selected element's chart_config in the dashboard state.
      const updatedEl = { ...selectedElement, chart_config: safeCfg };
      updateElementInDashboard(updatedEl);
      setHasUnsavedChanges(true);
    };

    // Common field changes
    const handleChartTitleChange = (val) => {
      const safeCfg = getSafeChartConfig(chartEl);
      safeCfg.options.plugins.title = {
        ...safeCfg.options.plugins.title,
        display: true,
        text: val,
      };
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };
    const handleXAxisLabelChange = (val) => {
      const safeCfg = getSafeChartConfig(chartEl);
      safeCfg.options.scales.x = {
        ...safeCfg.options.scales.x,
        title: { display: true, text: val },
      };
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };
    const handleYAxisLabelChange = (val) => {
      const safeCfg = getSafeChartConfig(chartEl);
      safeCfg.options.scales.y = {
        ...safeCfg.options.scales.y,
        title: { display: true, text: val },
      };
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };
    const handleSecondYAxisLabelChangeLocal = (val) => {
      setSecondYAxisLabel(val);
      const safeCfg = getSafeChartConfig(chartEl);
      if (!safeCfg.options.scales.y1) {
        safeCfg.options.scales.y1 = {
          position: 'right',
          beginAtZero: true,
          grid: { drawOnChartArea: false },
        };
      }
      safeCfg.options.scales.y1.title = {
        display: true,
        text: val,
      };
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };

    // For “Stack Bars?”
    const handleStackBarsChange = (checked) => {
      const safeCfg = getSafeChartConfig(chartEl);
      safeCfg.options.scales.x.stacked = checked;
      safeCfg.options.scales.y.stacked = checked;
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };

    // If combo => let user pick bar or line for each dataset
    const handleDatasetChartTypeChange = (index, val) => {
      const safeCfg = getSafeChartConfig(chartEl);
      const newDS = safeCfg.data.datasets.map((ds, idx) =>
        idx === index ? { ...ds, type: val } : ds
      );
      safeCfg.data.datasets = newDS;
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };

    const handleLegendChange = (checked) => {
      const safeCfg = getSafeChartConfig(chartEl);
      safeCfg.options.plugins.legend = {
        ...safeCfg.options.plugins.legend,
        display: checked,
      };
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };
    const handleLegendNameChange = (index, newLabel) => {
      const safeCfg = getSafeChartConfig(chartEl);
      const newDS = safeCfg.data.datasets.map((ds, idx) =>
        idx === index ? { ...ds, label: newLabel } : ds
      );
      safeCfg.data.datasets = newDS;
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };
    const handleDataLabelsChange = (checked) => {
      const safeCfg = getSafeChartConfig(chartEl);
      safeCfg.showDataLabels = checked;
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };
    const handleDataLabelOffsetChange = (value) => {
      const safeCfg = getSafeChartConfig(chartEl);
      safeCfg.dataLabelOffset = Number(value);
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };
    const handleGridlinesChange = (checked) => {
      const safeCfg = getSafeChartConfig(chartEl);
      safeCfg.options.scales.x.grid = {
        ...(safeCfg.options.scales.x.grid || {}),
        display: checked,
        drawBorder: checked,
      };
      safeCfg.options.scales.y.grid = {
        ...(safeCfg.options.scales.y.grid || {}),
        display: checked,
        drawBorder: checked,
      };
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };

    const handleCustomColorChange = (colorObj) => {
      const colorString = colorObj.toHexString();
      const safeCfg = getSafeChartConfig(chartEl);
      const newDS = safeCfg.data.datasets.map((ds, idx) => {
        if (idx === datasetIndex) {
          const dsType = ds.type?.toLowerCase() || 'line';
          if (dsType === 'line') {
            return {
              ...ds,
              _customColor: colorString, // <-- store the base color
              borderColor: colorString,
              backgroundColor: safeCfg.isArea ? hexToRGBA(colorString, 0.3) : colorString,
              pointBackgroundColor: colorString,
              pointBorderColor: colorString,
              _customColorUsed: true,
            };
          } else if (dsType === 'bar') {
            return {
              ...ds,
              _customColor: colorString, // <-- store the base color
              backgroundColor: colorString,
              borderColor: colorString,
              _customColorUsed: true,
            };
          }
          // Fallback for other chart types:
          return {
            ...ds,
            _customColor: colorString, // <-- store the base color
            backgroundColor: colorString,
            _customColorUsed: true,
          };
        }
        return ds;
      });
      safeCfg.data.datasets = newDS;
      updateElementInDashboard({ ...chartEl, chart_config: safeCfg });
    };

    const handleClearCustomColor = () => {
      if (!selectedElement) return;

      // Make a safe copy of the chart_config for the selected element
      const safeCfg = getSafeChartConfig(selectedElement);
      const dsArray = safeCfg.data?.datasets || [];

      // If the datasetIndex is valid, remove custom color flags for that dataset
      const newDatasets = dsArray.map((ds, idx) => {
        if (idx === datasetIndex) {
          // Remove _customColor, _customColorUsed, etc.
          const { _customColor, _customColorUsed, ...rest } = ds;

          // Also remove any leftover color fields so it reverts to default logic
          delete rest.borderColor;
          delete rest.backgroundColor;
          delete rest.pointBackgroundColor;
          delete rest.pointBorderColor;

          return rest;
        }
        return ds;
      });

      safeCfg.data.datasets = newDatasets;

      // Update the element in state => triggers re-render with default palette
      const updatedEl = { ...selectedElement, chart_config: safeCfg };
      updateElementInDashboard(updatedEl);
    };

    const availableChartTypes = ['Table', 'Bar', 'Line', 'Area', 'Pie', 'Combo'];

    return (
      <Form layout="vertical">
        <Form.Item label="Chart Type">
          <Select
            value={friendlyChartType}
            onChange={handleChartTypeChange}
            style={{ width: '100%' }}
          >
            {availableChartTypes.map((ct) => (
              <Select.Option key={ct} value={ct}>
                {ct}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Chart Title">
          <Input value={chartTitle} onChange={(e) => handleChartTitleChange(e.target.value)} />
        </Form.Item>
        <Form.Item label="X-Axis Label">
          <Input value={xAxisLabel} onChange={(e) => handleXAxisLabelChange(e.target.value)} />
        </Form.Item>
        <Form.Item label="Y-Axis Label">
          <Input value={yAxisLabel} onChange={(e) => handleYAxisLabelChange(e.target.value)} />
        </Form.Item>

        {hasSecondAxis && (
          <Form.Item label="Second Y-Axis Label">
            <Input
              value={secondYAxisLabel}
              onChange={(e) => handleSecondYAxisLabelChangeLocal(e.target.value)}
            />
          </Form.Item>
        )}

        {(friendlyChartType === 'Combo' || friendlyChartType === 'Bar') && (
          <Form.Item label="Stack Bars">
            <Switch
              checked={
                cfg.options?.scales?.x?.stacked === true && cfg.options?.scales?.y?.stacked === true
              }
              onChange={handleStackBarsChange}
            />
          </Form.Item>
        )}

        {friendlyChartType === 'Combo' && (
          <Form.Item label="Dataset Chart Types">
            {(cfg.data?.datasets || []).map((ds, idx) => (
              <div key={idx}>
                <Select
                  value={ds.type || 'bar'}
                  onChange={(val) => handleDatasetChartTypeChange(idx, val)}
                  style={{ width: '100%', marginBottom: 8 }}
                >
                  <Select.Option value="bar">Bar</Select.Option>
                  <Select.Option value="line">Line</Select.Option>
                </Select>
              </div>
            ))}
          </Form.Item>
        )}

        <Form.Item label="Legend">
          <Switch checked={legendDisplay} onChange={handleLegendChange} />
        </Form.Item>

        {legendDisplay &&
          (cfg.data?.datasets || []).map((ds, idx) => (
            <Form.Item key={idx} label={`Legend Name for Dataset #${idx + 1}`}>
              <Input
                value={ds.label || ''}
                onChange={(e) => handleLegendNameChange(idx, e.target.value)}
              />
            </Form.Item>
          ))}

        <Form.Item label="Data Labels">
          <Switch checked={showDataLabels} onChange={handleDataLabelsChange} />
        </Form.Item>
        {showDataLabels && (
          <Form.Item label="Data Label Offset">
            <InputNumber
              value={dataLabelOffset}
              onChange={handleDataLabelOffsetChange}
              style={{ width: '100%' }}
            />
          </Form.Item>
        )}

        <Form.Item label="Show Gridlines">
          <Switch
            checked={
              cfg.options?.scales?.x?.grid?.display !== false &&
              cfg.options?.scales?.y?.grid?.display !== false
            }
            onChange={handleGridlinesChange}
          />
        </Form.Item>

        <Form.Item label="Custom Color">
          <Row gutter={16}>
            <Col>
              <Select
                value={datasetIndex}
                onChange={setDatasetIndex}
                style={{ width: 120 }}
              >
                {(cfg.data?.datasets || []).map((ds, i) => (
                  <Select.Option key={i} value={i}>
                    {ds.label || `Dataset ${i + 1}`}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col>
              <ColorPicker onChange={handleCustomColorChange} />
            </Col>
            {/* NEW: "Clear" button to remove custom color */}
            <Col>
              <Button
                icon={<ClearOutlined />}
                onClick={handleClearCustomColor}
                type="text"
              />
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button onClick={() => setIsDataModalOpen(true)} style={{ width: '100%', marginTop: 10 }}>
            View Data
          </Button>
        </Form.Item>

        <div style={{ marginTop: 16 }}>
          <Button danger onClick={handleDeleteElement} style={{ marginRight: 8, marginTop: 10 }}>
            Delete Element
          </Button>
        </div>
      </Form>
    );
  };

  // The main “Sidebar” content
  const renderSidebarContent = () => {
    // If we are editing a selected element
    if (selectedElement && isEditing) {
      if (selectedElement.type === 'text') {
        // Text element
        return (
          <div>
            {/* Header row with "Text Options" + X */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 16,
              }}
            >
              <h4 style={{ margin: 0 }}>Text Options</h4>
              <Button
                type="text"
                style={{ fontSize: '16px' }}
                onClick={() => {
                  setIsEditing(false);
                  setSelectedElement(null);
                  setSidebarOpen(false);
                }}
              >
                ✕
              </Button>
            </div>

            {/* Text styling controls */}
            <Button
              type={selectedElement.isBold ? 'primary' : 'default'}
              onClick={() =>
                handleTextStyleChange(
                  selectedElement.dashboard_element_id || selectedElement.tempId,
                  'isBold',
                  !selectedElement.isBold
                )
              }
              style={{ marginRight: 8 }}
            >
              Bold
            </Button>
            <Button
              type={selectedElement.isItalic ? 'primary' : 'default'}
              onClick={() =>
                handleTextStyleChange(
                  selectedElement.dashboard_element_id || selectedElement.tempId,
                  'isItalic',
                  !selectedElement.isItalic
                )
              }
              style={{ marginRight: 8 }}
            >
              Italic
            </Button>

            <Button
              danger
              onClick={handleDeleteElement}
              style={{ marginTop: 16, display: 'block' }}
            >
              Delete Element
            </Button>
          </div>
        );
      } else {
        // Chart element
        return (
          <div>
            {/* Header row with "Chart Options" + X */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 16,
              }}
            >
              <h4 style={{ margin: 0 }}>Chart Options</h4>
              <Button
                type="text"
                style={{ fontSize: '16px' }}
                onClick={() => {
                  setIsEditing(false);
                  setSelectedElement(null);
                  setSidebarOpen(false);
                }}
              >
                ✕
              </Button>
            </div>

            {/* The chart-config form */}
            {renderDynamicControls(selectedElement)}
          </div>
        );
      }
    }

    // If no element is selected or not in editing mode => show “Add” UI
    return (
      <div>
        {/* Header row with "Add Chart" / "Add Text" + X */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 16
          }}
        >
          <h4 style={{ margin: 0 }}>
            {currentTab === 'text'}
          </h4>
          <Button
            type="text"
            style={{ fontSize: '16px' }}
            onClick={() => setSidebarOpen(false)}
          >
            ✕
          </Button>
        </div>

        <Select
          defaultValue="element"
          style={{ width: '100%', marginBottom: 20 }}
          onChange={(val) => setCurrentTab(val)}
          options={[
            { label: 'Add Chart', value: 'element' },
            { label: 'Add Text', value: 'text' },
          ]}
        />
        {currentTab === 'text' ? (
          <div>
            <Button
              onClick={() => handleAddText('Heading 1', 24)}
              style={{ marginBottom: 10, width: '100%' }}
            >
              H1 - Heading 1
            </Button>
            <Button
              onClick={() => handleAddText('Heading 2', 18)}
              style={{ marginBottom: 10, width: '100%' }}
            >
              H2 - Heading 2
            </Button>
            <Button
              onClick={() => handleAddText('Body', 14)}
              style={{ marginBottom: 10, width: '100%' }}
            >
              Body
            </Button>
          </div>
        ) : (
          <div>
            <Input
              placeholder="Search charts..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ marginBottom: 20 }}
            />
            {availableElements
              .filter((el) =>
                el.name?.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item) => (
                <Button
                  key={item.id}
                  style={{ marginBottom: 10, width: '100%', textAlign: 'left' }}
                  onClick={() => handleAddElement(item)}
                >
                  {item.name}
                </Button>
              ))}
          </div>
        )}
      </div>
    );
  };

  // “Spruce Templates” placeholder if no elements
  const SpruceTemplatesPlaceholder = ({ onSelectTemplate, onCreateCustom }) => (
    <div
      style={{
        border: '2px dashed #d9d9d9',
        borderRadius: '10px',
        padding: '50px',
        textAlign: 'center',
        background: '#ffff',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <h1>Spruce Models</h1>
      <div
        style={{
          display: 'flex',
          gap: '16px',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {dashboardTemplates.map((tmpl) => (
          <Card
            key={tmpl.id}
            style={{ width: 220, cursor: 'pointer' }}
            onClick={() => onSelectTemplate(tmpl)}
          >
            <Text strong>{tmpl.name}</Text>
            <div style={{ marginTop: 8, fontSize: '13px' }}>{tmpl.description}</div>
          </Card>
        ))}
        <Card
          style={{ width: 220, cursor: 'pointer', border: '1px dashed #1890ff' }}
          onClick={onCreateCustom}
        >
          <Text strong>Create Custom</Text>
          <div style={{ marginTop: 8, fontSize: '13px' }}>Start with a blank dashboard</div>
        </Card>
      </div>
    </div>
  );

  // ----------------------------------------------
  //  RENDER
  // ----------------------------------------------
  return (
    <div className="edit-dashboard-container" style={{ display: 'flex', height: '100%', position: 'relative' }}>
      {/* Optionally, an in-app prompt for unsaved changes */}
      <UnsavedChangesPrompt
        when={hasUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />

      {/* MAIN CONTENT */}
      <div
        className="main-content"
        style={{
          flex: isSidebarOpen ? '0 0 calc(100% - 300px)' : '1 1 100%',
          transition: 'flex 0.3s ease',
          overflow: 'hidden',
        }}
      >
        <div className="edit-dashboard">
          {/* Header */}
          <div className="dashboard-header-content">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                onClick={openDashboardModal}
              >
                {dashboard.name} <AppstoreOutlined style={{ fontSize: '16px', marginLeft: 4 }} />
              </Button>
              <Button
                style={{
                  marginLeft: '8px',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                onClick={openDashboardNameModal}
              >
                Create Dashboard
              </Button>
            </div>

            <div className="header-buttons">
              <Button
                type="primary"
                onClick={handleSaveDashboard}
                className="save-dashboard-button"
                style={{ marginRight: 8 }}
              >
                Save Dashboard
              </Button>
              <Button onClick={handleAddChartClick} style={{ marginRight: 8 }}>
                Add Chart
              </Button>

              <Dropdown overlay={refreshMenu} placement="bottomRight">
                <Button style={{ marginRight: 8 }}>
                  Refresh {isRefreshing ? <LoadingOutlined size="small" /> : <DownOutlined />}
                </Button>
              </Dropdown>

              <Dropdown overlay={shareMenu} placement="bottomRight">
                <Button>
                  Share/Export <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>

          {/* MAIN GRID AREA */}
          <div id="dashboard-container" style={{ marginTop: '60px' }}>
            {dashboard.elements.length === 0 ? (
              choseCustom ? (
                <EmptyDashboardPlaceholder onEnableEdit={handleAddChartClick} />
              ) : (
                <SpruceTemplatesPlaceholder
                  onSelectTemplate={handleSelectDataSourceModal}
                  onCreateCustom={() => setChoseCustom(true)}
                />
              )
            ) : (
              <ResponsiveGridLayout
                className="layout"
                layouts={layouts}
                cols={cols}
                breakpoints={breakpoints}
                rowHeight={30}
                margin={[10, 10]}
                onLayoutChange={handleLayoutChange}
                draggableHandle=".drag-handle"
                compactType={null}
                preventCollision={true}
                isDraggable={isEditing}
                isResizable={isEditing}
              >
                {dashboard.elements.map((memoElement) => {
                  const elementUniqueId = memoElement.dashboard_element_id
                    ? `db_${memoElement.dashboard_element_id}`
                    : `temp_${memoElement.tempId}`;

                  const selectedUniqueId = selectedElement
                    ? selectedElement.dashboard_element_id
                      ? `db_${selectedElement.dashboard_element_id}`
                      : `temp_${selectedElement.tempId}`
                    : null;

                  const isSelected = elementUniqueId === selectedUniqueId;

                  return (
                    <div key={elementUniqueId}>
                      <Card
                        bordered={memoElement.hasBorder}
                        className="drag-handle"
                        style={{
                          height: '100%',
                          position: 'relative',
                          border: isSelected ? '2px solid #1677ff' : '1px solid #ddd',
                          borderRadius: '4px',
                          backgroundColor: '#fff',
                          userSelect: 'none',
                        }}
                        bodyStyle={{ padding: 10, height: '100%' }}
                        onMouseDown={() => {}}
                      >
                        {/* Edit icon */}
                        <div
                          className="edit-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isSelected && isEditing) {
                              // Deselect if we click the same
                              setIsEditing(false);
                              setSelectedElement(null);
                              setSidebarOpen(false);
                            } else {
                              setIsEditing(true);
                              handleElementClick(memoElement);
                            }
                          }}
                        >
                          <EditOutlined />
                        </div>

                        {memoElement.type === 'text' ? (
                          <Typography.Paragraph
                            editable={
                              isEditing
                                ? {
                                    onChange: (val) =>
                                      handleTextEdit(
                                        val,
                                        memoElement.dashboard_element_id || memoElement.tempId
                                      ),
                                    icon: null,
                                    triggerType: ['text'],
                                  }
                                : false
                            }
                            style={{
                              fontSize: `${memoElement.fontSize || 16}px`,
                              fontWeight: memoElement.isBold ? 'bold' : 'normal',
                              fontStyle: memoElement.isItalic ? 'italic' : 'normal',
                              margin: 0,
                              height: '100%',
                            }}
                          >
                            {memoElement.content}
                          </Typography.Paragraph>
                        ) : memoElement.chart_config?.type === 'table' ? (
                          <TableVisualization config={memoElement.chart_config} />
                        ) : (
                          <Visualization config={memoElement.chart_config} />
                        )}
                      </Card>
                    </div>
                  );
                })}
              </ResponsiveGridLayout>
            )}
          </div>
        </div>
      </div>

      {/* RIGHT SIDEBAR */}
      {isSidebarOpen && (
        <div
          className="edit-home-sidebar"
          style={{
            position: 'fixed',
            top: '183px',        // Adjust to your header’s height
            right: '0',
            bottom: '60px',
            width: '300px',
            background: '#fff',
            borderLeft: '1px solid #ddd',
            borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd',
            borderRadius: '6px',
            overflowY: 'auto',
            padding: '16px',
          }}
        >
          {renderSidebarContent()}
        </div>
      )}

      {/* Modal: Unsaved changes */}
      <Modal
        title="Unsaved Changes"
        visible={isModalOpen}
        onOk={handleSaveDashboard}
        onCancel={handleExitWithoutSaving}
        okText="Save"
        cancelText="Exit Without Saving"
      >
        <p>You have unsaved changes. Do you want to save before leaving?</p>
      </Modal>

      {/* Modal: Dashboard selection */}
      <Modal
        visible={isDashboardsModalOpen}
        onCancel={closeDashboardModal}
        footer={null}
        width={1200}
        className="add-data-source-modal"
      >
        <Title level={4} style={{ textAlign: 'center', marginBottom: '24px', fontWeight: 'normal' }}>
          Select Dashboard
        </Title>
        <div style={{ maxHeight: '440px', overflowY: 'auto', padding: '16px' }}>
          {savedDashboards.length > 0 ? (
            <Row gutter={[16, 16]}>
              {savedDashboards.map((db) => (
                <Col xs={24} sm={12} md={8} lg={6} key={db.id}>
                  <Card
                    title={db.name}
                    hoverable
                    className="data-source-card"
                    actions={renderDashboardCardActions(db)}
                    onClick={() => {
                      if (!actionModalType) {
                        closeDashboardModal();
                        handleSelectDashboard(db.id);
                      }
                    }}
                    style={{ textAlign: 'center' }}
                  >
                    <div
                      className="thumbnail"
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <div className="details">
                        <Text type="secondary" style={{ fontSize: '12px' }}>
                          {db.updated_at
                            ? `Updated ${new Date(db.updated_at).toLocaleDateString()}`
                            : 'No updates yet'}
                        </Text>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Empty description="No dashboards available" style={{ marginTop: '20px' }} />
          )}
        </div>
      </Modal>

      {/* Modal: Rename / Delete */}
      <Modal
        title={actionModalType === 'rename' ? 'Rename Dashboard' : 'Confirm Deletion'}
        visible={isActionModalOpen}
        onCancel={closeActionModal}
        onOk={actionModalType === 'rename' ? handleRename : handleDelete}
        okText={actionModalType === 'delete' ? 'Delete' : 'Rename'}
        okButtonProps={actionModalType === 'delete' ? { danger: true } : {}}
        cancelText="Cancel"
      >
        {actionModalType === 'rename' ? (
          <Input value={newName} onChange={(e) => setNewName(e.target.value)} placeholder="Enter new name" />
        ) : (
          <p>Are you sure you want to delete this dashboard?</p>
        )}
      </Modal>

      {/* Modal: Name your new dashboard */}
      <Modal
        title="Name Your New Dashboard"
        visible={isDashboardNameModalOpen}
        onCancel={closeDashboardNameModal}
        onOk={handleCreateNewDashboard}
      >
        <Input
          placeholder="Enter dashboard name"
          value={newDashboardName}
          onChange={(e) => setNewDashboardName(e.target.value)}
        />
      </Modal>

      {/* Modal: Select a Data Source (for applying a template) */}
      <Modal
        title="Select a Data Source"
        visible={isDataSourceModalOpen}
        onCancel={() => setDataSourceModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setDataSourceModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={() => {
              if (!selectedDataSource) {
                message.error('Please select a data source first!');
                return;
              }
              handleSelectTemplate(selectedTemplate, selectedDataSource);
            }}
          >
            Create from Template
          </Button>,
        ]}
      >
        <Select
          style={{ width: '100%' }}
          placeholder="Choose a data source..."
          value={selectedDataSource}
          onChange={(val) => setSelectedDataSource(val)}
        >
          {dataSources.map((ds) => (
            <Select.Option key={ds.id} value={ds.id}>
              {ds.name}
            </Select.Option>
          ))}
        </Select>
      </Modal>

      {/* Modal: Schedule Refresh */}
      <Modal
        title="Schedule Dashboard Refresh"
        visible={isScheduleModalOpen}
        onCancel={() => setScheduleModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setScheduleModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="set" type="primary" onClick={handleScheduleRefresh}>
            Set Schedule
          </Button>,
        ]}
      >
        <div className="day-selection">
          <label>Select Days of the Week:</label>
          <div className="days-of-week">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day, index) => (
              <Checkbox
                key={index}
                value={day}
                checked={selectedDays.includes(day)}
                onChange={() => handleDaySelection(day)}
                style={{ marginRight: 8 }}
              >
                {day}
              </Checkbox>
            ))}
          </div>
        </div>
        <div className="time-input">
          <label>Select Time:</label>
          <Input
            placeholder="e.g., 12:00 PM"
            value={timeInput}
            onChange={(e) => setTimeInput(e.target.value)}
          />
          {timeError && <p style={{ color: 'red' }}>{timeError}</p>}
        </div>
        <div className="repeat-selection">
          <label>Repeat:</label>
          <Select
            value={repeatOption}
            onChange={(val) => setRepeatOption(val)}
            style={{ width: '100%' }}
          >
            <Select.Option value="none">None</Select.Option>
            <Select.Option value="daily">Daily</Select.Option>
            <Select.Option value="weekly">Weekly</Select.Option>
            <Select.Option value="monthly">Monthly</Select.Option>
          </Select>
        </div>
      </Modal>

      {/* Modal: “View Data” for the selected chart element */}
      <Modal
        visible={isDataModalOpen}
        footer={null}
        onCancel={() => setIsDataModalOpen(false)}
        width={800}
        bodyStyle={{ padding: '20px' }}
      >
        {selectedElement?.sql_query && (
          <p style={{ marginBottom: '16px' }}>
            <strong>SQL Query:</strong>
            <br />
            <br />
            {selectedElement.sql_query}
          </p>
        )}

        {selectedElement?.chart_config?.data ? (
          <>
            <p>
              <strong>Query Results:</strong>
            </p>
            <div style={{ marginTop: '16px' }}>
              {/* If you have a helper that displays chart data in a table: */}
              <TableVisualization config={selectedElement.chart_config} />
            </div>
          </>
        ) : (
          <p>No data available.</p>
        )}
      </Modal>
    </div>
  );
};

export default Home;
