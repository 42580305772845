import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { Spin, Typography, Button } from 'antd';

const { Title, Paragraph } = Typography;

const Welcome = () => {
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const checkEmailVerification = async () => {
      try {
        // If the user is signed in, reload to get the latest email verification status
        if (auth.currentUser) {
          await auth.currentUser.reload();

          if (auth.currentUser.emailVerified) {
            setVerified(true);
          }
        } else {
          setError("No user is currently signed in. Please sign in first.");
        }
      } catch (err) {
        console.error("Error checking verification:", err);
        setError(err.message || "Could not check email verification status.");
      } finally {
        setLoading(false);
      }
    };

    checkEmailVerification();
  }, [auth]);

  // While we're reloading the user or checking
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin size="large" />
      </div>
    );
  }

  // If there was an error verifying, display it
  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Title level={4}>Error</Title>
        <Paragraph>{error}</Paragraph>
        <Button onClick={() => window.location.reload()}>Try Again</Button>
      </div>
    );
  }

  // If the user is still not verified, prompt them
  if (!verified) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Title level={4}>Email Not Verified</Title>
        <Paragraph>
          Please verify your email by clicking the link in your inbox, then come back here and refresh.
        </Paragraph>
        <Button onClick={() => window.location.reload()}>Refresh</Button>
      </div>
    );
  }

  // If verified, show the welcome message
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <Title level={2}>Welcome to Spruce!</Title>
      <Paragraph>Your email is now verified. Click Continue to get started.</Paragraph>
      <Button type="primary" onClick={() => navigate('/home')}>
        Continue
      </Button>
    </div>
  );
};

export default Welcome;
