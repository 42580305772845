import React from 'react';
import { Table } from 'antd';

const TableVisualization = ({ config }) => {
  // Use provided table config values (or fallback defaults)
  const {
    columns,
    dataSource,
    pagination = { pageSize: 9 },
    loading,
    bordered = true,
    scroll,
    showHeader = true,
    size = 'small',
    onChange,
  } = config;

  // Warn if no columns/dataSource are provided.
  if (!columns || !dataSource) {
    console.warn('No valid table columns or dataSource provided.');
    return null;
  }

  // Optionally, you can enhance each column with sorting and filtering.
  const enhancedColumns = columns.map((col) => ({
    ...col,
    sorter: (a, b) => {
      const valueA = a[col.dataIndex];
      const valueB = b[col.dataIndex];
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      }
      return 0;
    },
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <input
          placeholder={`Search ${col.title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') confirm();
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <button onClick={() => confirm()} style={{ marginRight: 8 }}>
          OK
        </button>
      </div>
    ),
    onFilter: (value, record) =>
      record[col.dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    filterIcon: (filtered) => (
      <span style={{ color: filtered ? '#1890ff' : undefined }}>🔍</span>
    ),
  }));

  // Define a unique row key generator.
  const generateRowKey = (record, index) => `row-${index}`;

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <Table
        columns={enhancedColumns}
        dataSource={dataSource}
        pagination={pagination}
        loading={loading}
        bordered={bordered}
        rowKey={generateRowKey}
        scroll={scroll}
        showHeader={showHeader}
        size={size}
        onChange={onChange}
      />
    </div>
  );
};

export default TableVisualization;
