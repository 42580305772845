import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  getAuth,
  applyActionCode,
  confirmPasswordReset
} from 'firebase/auth';
import { Typography, Input, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const ActionManagement = () => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const modeParam = searchParams.get('mode'); // 'verifyEmail' or 'resetPassword'
    const codeParam = searchParams.get('oobCode');

    if (!modeParam || !codeParam) {
      // Missing required query params
      setError('Invalid or expired link.');
      setLoading(false);
      return;
    }

    setMode(modeParam);
    setOobCode(codeParam);

    if (modeParam === 'verifyEmail') {
      // For email verification, we can immediately apply the action code
      applyActionCode(auth, codeParam)
        .then(() => {
          setMessage('Your email has been verified!');
        })
        .catch((err) => {
          console.error('Verification error:', err);
          setError('Could not verify email.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (modeParam === 'resetPassword') {
      // For reset password, we wait until the user submits a new password
      setLoading(false);
    } else {
      // Unrecognized mode
      setError('Invalid or expired link.');
      setLoading(false);
    }
  }, [auth, searchParams]);

  // Handler for resetting password
  const handleResetPassword = async () => {
    if (!newPassword) {
      setError('Please enter a new password.');
      return;
    }

    setLoading(true);
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessage('Password has been reset successfully! Please log in with your new password.');
    } catch (err) {
      console.error('Password reset error:', err);
      // Use a simple, user-friendly error message instead of err.message
      setError('Failed to reset password. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Loading spinner while we decide what to do
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '200px' }}>
        <LoadingOutlined size="large" />
        <Paragraph>Processing...</Paragraph>
      </div>
    );
  }

  // If we have a generic error
  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '200px' }}>
        <Title level={4}>
          {mode === 'verifyEmail' ? 'Verification Error' : 'Reset Error'}
        </Title>
        <Paragraph>{error}</Paragraph>
        <Button type="primary" onClick={() => navigate('/login')}>
          Go to Login
        </Button>
      </div>
    );
  }

  // If we have a success message
  if (message) {
    return (
      <div style={{ textAlign: 'center', marginTop: '200px' }}>
        <Title level={4}>
          {mode === 'verifyEmail' ? 'Email Verified' : 'Reset Successful'}
        </Title>
        <Paragraph>{message}</Paragraph>
        <Button type="primary" onClick={() => navigate('/login')}>
          Go to Login
        </Button>
      </div>
    );
  }

  // If mode is 'resetPassword' (no success/error yet), show the reset form
  if (mode === 'resetPassword') {
    return (
      <div style={{ textAlign: 'center', marginTop: '200px' }}>
        <Title level={4}>Reset Your Password</Title>
        <div style={{ maxWidth: '400px', margin: '20px auto' }}>
          <Input.Password
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
          <Button
            type="primary"
            onClick={handleResetPassword}
            style={{ width: '100%' }}
          >
            Set New Password
          </Button>
        </div>
      </div>
    );
  }

  // Fallback if somehow we get here without a recognized mode
  return (
    <div style={{ textAlign: 'center', marginTop: '200px' }}>
      <Title level={4}>Invalid action</Title>
      <Button type="primary" onClick={() => navigate('/login')}>
        Go to Login
      </Button>
    </div>
  );
};

export default ActionManagement;