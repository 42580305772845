import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Typography } from 'antd';
import { UserOutlined, FileTextOutlined } from '@ant-design/icons';
import './Settings.css'; // Ensure this path is correct


const { Title } = Typography;

const Settings = () => {
  const navigate = useNavigate();

  const settingsOptions = [
    {
      title: 'User Access',
      description: 'View and manage user access',
      icon: <UserOutlined style={{ fontSize: '24px', color: '#1677ff' }} />,
      link: '/settings/user-access',
    },
    {
      title: 'Archived Chats',
      description: 'View and manage archived chats',
      icon: <FileTextOutlined style={{ fontSize: '24px', color: '#1677ff' }} />,
      link: '/settings/archived-chats',
    },
  ];

  return (
    <div className="settings-page">
      <Title level={4} style={{ marginBottom: '40px'}}>Settings</Title>
      <Row gutter={[16, 16]}>
        {settingsOptions.map((item, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card
              className="card-style"
              hoverable
              onClick={() => navigate(item.link)}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                {item.icon}
                <div>
                  <Typography.Text strong>{item.title}</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {item.description}
                  </Typography.Text>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Settings;
