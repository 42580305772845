import React, { useState, useEffect } from 'react';
import axios from '../fetchWithCsrf';
import Sidebar from '../components/Sidebar';
import ChatInterface from '../components/ChatInterface';
import './Insights.css';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import API_BASE_URL from '../config';

const Insights = () => {
  const [chats, setChats] = useState(null);
  const [loadingChats, setLoadingChats] = useState(true);

  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [unsavedChatId, setUnsavedChatId] = useState(null);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [currentChatDataSource, setCurrentChatDataSource] = useState(null);
  const [isFirstMessageSent, setIsFirstMessageSent] = useState(false);

  const [datasources, setDatasources] = useState([]);

  // 2) On mount, load datasources & chats once. Then open the first chat if any.
  useEffect(() => {
    async function initLoad() {
      // 1) Fetch datasources
      let dsResp;
      try {
        dsResp = await axios.get(`${API_BASE_URL}/datasources/`, {
          withCredentials: true,
        });
        setDatasources(dsResp.data || []);
      } catch (error) {
        console.error('[fetchDatasources] Error:', error);
      }

      // 2) Fetch chats
      setLoadingChats(true);
      let fetchedChats = [];
      try {
        const resp = await axios.get(`${API_BASE_URL}/chats/`, {
          withCredentials: true,
        });
        fetchedChats = resp.data || [];
        if (!Array.isArray(fetchedChats)) {
          fetchedChats = [];
        }
        fetchedChats.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setChats(fetchedChats);
      } catch (err) {
        console.error('[fetchChats] Error fetching chats:', err);
      }
      setLoadingChats(false);

      // 3) If no chats => create new. If we have chats => open the first.
      if (fetchedChats.length === 0) {
        // Inline the "createNewChat" logic to avoid the ESLint dependency warning:
        const tempId = `temp_${Date.now()}`;
        const temporaryChat = { id: tempId, title: 'New Chat' };
        setChats((prev) => [temporaryChat, ...(prev || [])]);
        setCurrentChat(tempId);
        setUnsavedChatId(tempId);
        setMessages([]);
        setIsFirstMessageSent(false);
        if (dsResp?.data?.length > 0) {
          setCurrentChatDataSource(dsResp.data[0].id);
        }
      } else {
        // If we have a first chat, open it & load messages
        const firstChatId = fetchedChats[0].id;
        setCurrentChat(firstChatId);

        try {
          // [inline "fetchChatContent"]
          const chatResp = await axios.get(
            `${API_BASE_URL}/chats/${firstChatId}/`,
            { withCredentials: true }
          );
          const { data_source_id: dsId, first_message_sent } = chatResp.data;
          setCurrentChatDataSource(dsId || null);

          const msgResp = await axios.get(
            `${API_BASE_URL}/chats/${firstChatId}/messages/`,
            { withCredentials: true }
          );
          const rawMsgs = msgResp.data || [];

          // Make sure to return from map => no ESLint array-callback-return error
          const processed = rawMsgs.map((m) => {
            const fromUser = (m.from_user === true || m.from_user === 'user')
              ? 'user'
              : 'system';
            if (m.chart_config) {
              return {
                ...m,
                type: 'chart',
                chartConfig: m.chart_config,
                from_user: fromUser,
                sqlQuery: m.sql_query,
                commentary: m.text,
                plainText: m.data?.plain_text,
                data: m.data,
                databaseId: m.database_id,
                messageId: m.message_id || m.id,
                drillDownQuestions: m.drill_down_questions || [],
              };
            }
            // If no chart, just return the original with from_user
            return { ...m, from_user: fromUser };
          });

          processed.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
          setMessages(processed);

          if (processed.length === 0) {
            setIsFirstMessageSent(false);
            if (!dsId && dsResp?.data?.length > 0) {
              setCurrentChatDataSource(dsResp.data[0].id);
            }
          } else {
            setIsFirstMessageSent(!!first_message_sent);
          }
        } catch (chatErr) {
          console.error('Error fetching first chat content:', chatErr);
        }
      }
    }

    initLoad();
  // We have no missing dependencies in this effect => no ESLint warning
  }, []);

  /**
   * 3) If user clicks a different chat in the sidebar, we inline the "fetchChatContent" logic here.
   */
  const handleChatSelect = async (chatId) => {
    setCurrentChat(chatId);

    if (chatId === unsavedChatId) {
      // brand-new in the frontend
      setMessages([]);
      return;
    }

    try {
      // 1) chat details
      const chatResponse = await axios.get(`${API_BASE_URL}/chats/${chatId}/`, { withCredentials: true });
      const { data_source_id: dsId, first_message_sent } = chatResponse.data;
      setCurrentChatDataSource(dsId || null);

      // 2) messages
      const messagesResponse = await axios.get(`${API_BASE_URL}/chats/${chatId}/messages/`, {
        withCredentials: true,
      });
      const rawMsgs = messagesResponse.data || [];

      const processed = rawMsgs.map((m) => {
        const fromUser = m.from_user === true || m.from_user === 'user' ? 'user' : 'system';
        if (m.chart_config) {
          return {
            ...m,
            type: 'chart',
            chartConfig: m.chart_config,
            from_user: fromUser,
            sqlQuery: m.sql_query,
            commentary: m.text,
            plainText: m.data?.plain_text,
            data: m.data,
            databaseId: m.database_id,
            messageId: m.message_id || m.id,
            drillDownQuestions: m.drill_down_questions || [],
          };
        }
        return { ...m, from_user: fromUser };
      });

      processed.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setMessages(processed);

      if (processed.length === 0) {
        setIsFirstMessageSent(false);
        if (!dsId && datasources.length > 0) {
          setCurrentChatDataSource(datasources[0].id);
        }
      } else {
        setIsFirstMessageSent(!!first_message_sent);
      }
    } catch (error) {
      console.error('[handleChatSelect] Error fetching chat content:', error);
    }
  };

  /**
   * Create an unsaved chat in the UI.
   */
  const createNewChat = () => {
    const tempId = `temp_${Date.now()}`;
    const temporaryChat = { id: tempId, title: 'New Chat' };

    setChats((prev) => {
      const safePrev = Array.isArray(prev) ? prev : [];
      return [temporaryChat, ...safePrev];
    });

    setCurrentChat(tempId);
    setUnsavedChatId(tempId);
    setMessages([]);
    setIsFirstMessageSent(false);

    if (datasources.length > 0) {
      setCurrentChatDataSource(datasources[0].id);
    } else {
      setCurrentChatDataSource(null);
    }
  };

  /**
   * Save a newly created chat to the server.
   */
  const saveChat = async (title) => {
    const newChat = { title };
    try {
      const response = await axios.post(`${API_BASE_URL}/chats/`, newChat, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      const addedChat = response.data;

      setChats((prevChats) =>
        prevChats.map((c) => (c.id === unsavedChatId ? addedChat : c))
      );
      setCurrentChat(addedChat.id);
      setUnsavedChatId(null);
      return addedChat;
    } catch (error) {
      console.error('[saveChat] Error saving chat:', error.response?.data || error.message);
      return null;
    }
  };

  /**
   * Send a message => updates DB
   */
  const sendMessage = async (chatId, newMessage, dataSourceId) => {
    if (!chatId || chatId === unsavedChatId) {
      // brand-new
      const savedChat = await saveChat(newMessage.text.slice(0, 20));
      if (!savedChat) return;
      chatId = savedChat.id;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/fetch_data/`,
        {
          user_input: newMessage.text,
          chat_id: chatId,
          data_source_id: dataSourceId,
        },
        { withCredentials: true }
      );
      return response;
    } catch (error) {
      console.error('[sendMessage] Error:', error);
      throw error;
    }
  };

  /**
   * Delete chat
   */
  const deleteChat = async (chatId) => {
    const chatToDelete = chats?.find((c) => c.id === chatId);
    const updatedChats = chats?.filter((c) => c.id !== chatId) || [];
    setChats(updatedChats);

    if (currentChat === chatId) {
      setCurrentChat(null);
      setMessages([]);
      setCurrentChatDataSource(null);
      setIsFirstMessageSent(false);
    }

    if (chatToDelete && chatToDelete.id !== unsavedChatId) {
      try {
        await axios.delete(`${API_BASE_URL}/chats/${chatToDelete.id}/`, { withCredentials: true });
      } catch (error) {
        console.error('Error deleting chat:', error);
        setChats((prev) => [...(prev || []), chatToDelete]);
        if (currentChat === null) setCurrentChat(chatId);
      }
    } else if (chatToDelete && chatToDelete.id === unsavedChatId) {
      console.warn('Attempted to delete an unsaved chat.');
    }
  };

  return (
    <div className="insights">
      <Sidebar
        chats={Array.isArray(chats) ? chats : []}
        setChats={setChats}
        currentChat={currentChat}
        addChat={createNewChat}
        deleteChat={deleteChat}
        setCurrentChat={handleChatSelect}  // calls inline logic
        showArchiveModal={showArchiveModal}
        setShowArchiveModal={setShowArchiveModal}
      />

      {loadingChats ? (
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <>
          {currentChat ? (
            <ChatInterface
              currentChat={currentChat}
              messages={messages}
              updateChat={sendMessage}
              currentChatDataSource={currentChatDataSource}
              setCurrentChatDataSource={setCurrentChatDataSource}
              isFirstMessageSent={isFirstMessageSent}
              setIsFirstMessageSent={setIsFirstMessageSent}
              datasources={datasources}
            />
          ) : (
            <div style={{ flex: 1, padding: '20px' }}>
              <h2>Please select or create a chat</h2>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Insights;
