import React, { useEffect, useState } from 'react';
import { Table, Button, Typography, Modal, message } from 'antd';
import { LeftOutlined, DeleteOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from '../fetchWithCsrf';
import './ArchivedChats.css';
import API_BASE_URL from "../config";

const { Title } = Typography;

const ArchivedChats = () => {
  const [archivedChats, setArchivedChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchArchivedChats();
  }, []);

  const fetchArchivedChats = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-archived-chats/`, {
        withCredentials: true,
      });
      setArchivedChats(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching archived chats:', error);
      setLoading(false);
    }
  };

  const handleUnarchive = async (chatId) => {
    try {
      await axios.patch(`${API_BASE_URL}/unarchive-chat/${chatId}/`, {}, { withCredentials: true });
      setArchivedChats(archivedChats.filter((chat) => chat.id !== chatId));
      message.success('Chat successfully unarchived.');
    } catch (error) {
      console.error('Error unarchiving chat:', error);
      message.error('Failed to unarchive the chat.');
    }
  };

  const handleDeleteChat = (chatId) => {
    setChatToDelete(chatId);
    setIsModalVisible(true);
  };

  const confirmDeleteChat = async () => {
    if (chatToDelete) {
      try {
        await axios.delete(`${API_BASE_URL}/chats/${chatToDelete}/?permanent=true`, { withCredentials: true });
        setArchivedChats(archivedChats.filter((chat) => chat.id !== chatToDelete));
        message.success('Chat successfully deleted.');
      } catch (error) {
        console.error('Error deleting chat:', error);
        message.error('Failed to delete the chat.');
      }
      setIsModalVisible(false);
      setChatToDelete(null);
    }
  };

  const cancelDelete = () => {
    setIsModalVisible(false);
    setChatToDelete(null);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: 'Created On',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => <Typography.Text>{new Date(date).toLocaleDateString()}</Typography.Text>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="link"
            icon={<FolderOpenOutlined />}
            onClick={() => handleUnarchive(record.id)}
          >
            Unarchive
          </Button>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteChat(record.id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="archived-chats-page">
      <div className="page-header">
        <div className="back-button-container">
          <Typography.Link
            onClick={() => navigate('/settings')}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: 24,
              color: '#1677ff'
            }}
          >
            <LeftOutlined style={{ marginRight: 4 }} />
            Back
          </Typography.Link>
        </div>
        <Title level={4} style={{marginBottom: 40}} className="page-title">
          Archived Chats
        </Title>
      </div>
      <Table
          columns={columns}
          dataSource={archivedChats}
          loading={loading}
          rowKey={(record) => record.id}
          bordered
          pagination={{pageSize: 5 }}
        className="archived-chats-table"
        locale={{ emptyText: 'No archived chats available.' }}
      />
      <Modal
        title="Confirm Delete"
        visible={isModalVisible}
        onOk={confirmDeleteChat}
        onCancel={cancelDelete}
        okText="Delete"
        okButtonProps={{ danger: true }}
        cancelText="Cancel"
      >
        <Typography.Text>
          Are you sure you want to permanently delete this chat? This will also delete any associated elements.
        </Typography.Text>
      </Modal>
    </div>
  );
};

export default ArchivedChats;
