import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Input,
  Card,
  Row,
  Col,
  Tooltip,
  Typography,
  message,
  Empty,
  Select,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  AppstoreOutlined,
  PieChartOutlined,
  LineChartOutlined,
  TableOutlined,
  BarChartOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import axios from '../fetchWithCsrf';
import './Charts.css';
import API_BASE_URL from "../config";

const { Title, Text } = Typography;
const { Option } = Select;

const Charts = () => {
  const [elements, setElements] = useState([]);
  const [dataSources, setDataSources] = useState([]); // Real data sources
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [newName, setNewName] = useState('');
  const navigate = useNavigate();

  // Filtering
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedChartType, setSelectedChartType] = useState('');
  const [selectedDataSource, setSelectedDataSource] = useState('');

  // Fetch elements on mount
  useEffect(() => {
    fetchElements();
    fetchDataSources();
  }, []);

  const fetchElements = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-elements/`, {
        withCredentials: true,
      });
      setElements(response.data || []);
    } catch (error) {
      console.error('Error fetching elements:', error);
    }
  };

  const fetchDataSources = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/datasources/`);
      setDataSources(response.data || []);
    } catch (error) {
      console.error('Error fetching data sources:', error);
      message.error('Failed to fetch data sources.');
    }
  };

  // Handling Modals (rename, delete, etc.)
  const openModal = (type, item = null) => {
    setModalType(type);
    setSelectedItem(item);
    setNewName(item?.name || 'New Element');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setNewName('');
  };

  const handleRename = async () => {
    // If it's a dashboard vs. an element, you might have different endpoints
    // For simplicity here, we assume everything is "element"
    if (!selectedItem) return;
    const url = `${API_BASE_URL}/rename-element/${selectedItem.id}/`;

    try {
      await axios.patch(
        url,
        { name: newName },
        { withCredentials: true }
      );
      setElements((prev) =>
        prev.map((el) => (el.id === selectedItem.id ? { ...el, name: newName } : el))
      );
      message.success('Element renamed successfully!');
    } catch (error) {
      console.error('Error renaming:', error);
      message.error('Failed to rename element.');
    }
    closeModal();
  };

  const handleDelete = async () => {
    if (!selectedItem) return;
    const url = `${API_BASE_URL}/delete-element/${selectedItem.id}/`;
    try {
      await axios.delete(url, { withCredentials: true });
      setElements((prev) => prev.filter((el) => el.id !== selectedItem.id));
      message.success('Element deleted successfully!');
    } catch (error) {
      console.error('Error deleting:', error);
      message.error('Failed to delete element.');
    }
    closeModal();
  };

  const handleDuplicate = async (element) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/duplicate-element/${element.id}/`,
        {},
        { withCredentials: true }
      );
      setElements((prev) => [...prev, response.data.new_element]);
      message.success('Element duplicated successfully!');
    } catch (error) {
      console.error('Error duplicating element:', error);
      message.error('Failed to duplicate element.');
    }
  };

  const getFriendlyChartType = (chartConfig = {}) => {
    if (chartConfig.combo) return 'Combo';
    if (chartConfig.isArea) return 'Area';
    if (chartConfig.type) {
      return chartConfig.type.charAt(0).toUpperCase() + chartConfig.type.slice(1);
    }
    return 'Unknown';
  };

  // Icon determination
  // Icon determination
  const renderIcon = (type, chartConfig = {}) => {
    let finalType = type;

    if (type === 'chart') {
      // new config-based approach
      finalType = chartConfig?.type || 'unknown';
    }

    // Add checks for additional flags
    if (chartConfig.combo) {
      finalType = 'combo';
    } else if (chartConfig.isArea) {
      finalType = 'area';
    }

    // Convert to lowercase
    const resolved = finalType.toLowerCase();

    switch (resolved) {
      case 'bar':
        return <BarChartOutlined />;
      case 'line':
      case 'area':
      case 'combo':
        return <LineChartOutlined />;
      case 'pie':
        return <PieChartOutlined />;
      case 'table':
        return <TableOutlined />;
      case 'value':
        return <NumberOutlined />;
      case 'dashboard':
        return <AppstoreOutlined />;
      default:
        return <AppstoreOutlined />;
    }
  };

  // For displaying "Updated at" if you have it
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  // Collect all chart types from the new config
  const allChartTypes = Array.from(
    new Set(
      elements
        .map((el) =>
          el.chart_config?.type ? el.chart_config.type.toLowerCase() : null
        )
        .filter(Boolean)
    )
  );

  // Build data source <Option>s
  const dataSourceOptions = dataSources.map((ds) => (
    <Option key={ds.id} value={ds.id}>
      {ds.name}
    </Option>
  ));

  // Filter the list
  const filteredElements = elements.filter((element) => {
    // name filter
    const matchesName = element.name
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    // chart type filter
    const rawType = element.chart_config?.type?.toLowerCase() || '';
    const matchesType = !selectedChartType || rawType === selectedChartType.toLowerCase();

    // data source
    const matchesDataSource =
      !selectedDataSource || element.database_id === selectedDataSource;

    return matchesName && matchesType && matchesDataSource;
  });

  // Action icons on each Card
  const renderCardActions = (item) => [
    <Tooltip title="Rename" key="edit">
      <EditOutlined
        onClick={(e) => {
          e.stopPropagation();
          openModal('rename', item);
        }}
      />
    </Tooltip>,
    <Tooltip title="Duplicate" key="duplicate">
      <CopyOutlined
        onClick={(e) => {
          e.stopPropagation();
          handleDuplicate(item);
        }}
      />
    </Tooltip>,
    <Tooltip title="Delete" key="delete">
      <DeleteOutlined
        onClick={(e) => {
          e.stopPropagation();
          openModal('delete', item);
        }}
      />
    </Tooltip>,
  ];

  // Render
  return (
    <div className="charts-page">
      <Title level={4}>My Charts</Title>

      {/* Filter bar */}
      <Row gutter={16} style={{ marginBottom: 24, marginTop: 24 }}>
        <Col>
          <Input
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            allowClear
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Select
            placeholder="Filter by chart type"
            value={selectedChartType || undefined}
            onChange={(val) => setSelectedChartType(val)}
            allowClear
            style={{ width: 200 }}
          >
            {allChartTypes.map((type) => {
              // capitalizing first letter
              const label = type.charAt(0).toUpperCase() + type.slice(1);
              return (
                <Option key={type} value={type}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col>
          <Select
            placeholder="Filter by data source"
            value={selectedDataSource || undefined}
            onChange={(val) => setSelectedDataSource(val)}
            allowClear
            style={{ width: 200 }}
          >
            {dataSourceOptions}
          </Select>
        </Col>
      </Row>

      {/* The list */}
      {filteredElements.length > 0 ? (
        <Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
          {filteredElements.map((el) => {
            const updatedAt = el.updated_at ? formatDate(el.updated_at) : 'No updates yet';
            const chartType = getFriendlyChartType(el.chart_config);

            return (
              <Col xs={24} sm={12} md={8} lg={6} key={el.id}>
                <Card
                  title={el.name || 'Unnamed Element'}
                  className="card-style"
                  hoverable
                  actions={renderCardActions(el)}
                  onClick={() => navigate(`/element/${el.id}`)}
                >
                  <div className="thumbnail">
                    <div className="icon-wrapper">
                      {renderIcon(el.type, el.chart_config)}
                    </div>
                    <div className="details">
                      <p className="type-name">{chartType}</p>
                      <Text type="secondary" style={{ fontSize: '12px' }}>
                        Updated {updatedAt}
                      </Text>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty description="No matching charts" style={{ marginTop: '40px' }} />
      )}

      {/* Confirm / Rename / Delete Modal */}
      <Modal
        title={
          modalType === 'rename'
            ? 'Rename Element'
            : modalType === 'delete'
            ? 'Confirm Deletion'
            : ''
        }
        visible={isModalOpen}
        onOk={modalType === 'rename' ? handleRename : handleDelete}
        onCancel={closeModal}
        okText={modalType === 'delete' ? 'Delete' : 'Confirm'}
        okButtonProps={modalType === 'delete' ? { danger: true } : {}}
        cancelText="Cancel"
      >
        {modalType === 'rename' ? (
          <Input
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Enter name"
          />
        ) : (
          <p>Are you sure you want to delete this element?</p>
        )}
      </Modal>
    </div>
  );
};

export default Charts;
