import axios from 'axios';

// Function to get the CSRF token from the cookie

// Include credentials (cookies) in all requests
axios.defaults.withCredentials = true;

const getCsrfToken = () => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, 'csrftoken'.length + 1) === ('csrftoken=')) {
        cookieValue = decodeURIComponent(cookie.substring('csrftoken'.length + 1));
        break;
      }
    }
  }
  console.log('Retrieved CSRF Token from cookies:', cookieValue); // Debug logging
  return cookieValue;
};

// Set up axios to include the CSRF token in the headers of requests
axios.interceptors.request.use(
  (config) => {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
      console.log('CSRF Token set in header:', csrfToken); // Debug logging
    } else {
      console.warn('CSRF Token not found in cookies'); // Debug logging
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
