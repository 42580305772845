import React, { useState } from 'react';
import axios from '../fetchWithCsrf';
import { Menu, Dropdown, Button, Modal } from 'antd';
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons';
import './Sidebar.css';
import API_BASE_URL from "../config";

const Sidebar = ({ chats, currentChat, addChat, deleteChat, setCurrentChat, setChats }) => {
    const [modal, setModal] = useState({
        visible: false,
        type: '', // 'delete' or 'archive'
        chat: null,
    });

    const handleActionClick = (type, chat) => {
        setModal({ visible: true, type, chat });
    };

    const handleConfirmAction = async () => {
        const { type, chat } = modal;
        try {
            if (type === 'delete') {
                await deleteChat(chat.id);
                setChats((prevChats) => prevChats.filter((c) => c.id !== chat.id));
            } else if (type === 'archive') {
                await axios.patch(`${API_BASE_URL}/archive-chat/${chat.id}/`, {}, { withCredentials: true });
                setChats((prevChats) => prevChats.filter((c) => c.id !== chat.id));
            }
        } catch (error) {
            console.error(`Error during ${type} action:`, error);
        } finally {
            setModal({ visible: false, type: '', chat: null });
        }
    };

    const renderMenu = (chat) => (
        <Menu>
            <Menu.Item onClick={() => handleActionClick('delete', chat)}>Delete</Menu.Item>
            <Menu.Item onClick={() => handleActionClick('archive', chat)}>Archive</Menu.Item>
        </Menu>
    );

    return (
        <div className="sidebar-container" style={{ width: '250px', padding: '10px', borderRight: '1px solid #f0f0f0', height: '100vh' }}>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                className="new-chat-button"
                onClick={addChat}
                block
                style={{ marginBottom: '20px', marginTop: '90px' }}
            >
                New Chat
            </Button>
            <Menu
                mode="vertical"
                selectedKeys={[currentChat]}
                className="sidebar-menu"
                style={{border: 'none', overflowY: 'auto', height: 'calc(100% - 160px)'}}
            >
                {chats.filter((chat) => !chat.archived).map((chat) => (
                    <Menu.Item
                        key={chat.id}
                        onClick={() => setCurrentChat(chat.id)}
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            backgroundColor: chat.id === currentChat ? '#e6f7ff' : '',
                            borderRadius: '8px',
                        }}
                        className={`menu-item ${chat.id === currentChat ? 'selected' : ''}`}
                    >
                        <span
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                flex: '1 1 auto',
                                paddingRight: '30px',
                            }}
                        >
                            {chat.title}
                        </span>
                        <div className="ellipsis-wrapper">
                            <Dropdown overlay={renderMenu(chat)} trigger={['click']}>
                                <EllipsisOutlined
                                    className="Ellipsis"
                                    onClick={(e) => e.stopPropagation()}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        fontSize: '18px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Dropdown>
                        </div>
                    </Menu.Item>
                ))}
            </Menu>
            <Modal
                title={modal.type === 'delete' ? 'Delete Chat' : 'Archive Chat'}
                visible={modal.visible}
                onCancel={() => setModal({visible: false, type: '', chat: null})}
                onOk={handleConfirmAction}
                okText={modal.type === 'delete' ? 'Delete' : 'Archive'}
                cancelText="Cancel"
                okButtonProps={modal.type === 'delete' ? { danger: true } : {}}
            >
                <p>
                    {modal.type === 'delete'
                        ? `Are you sure you want to delete the chat "${modal.chat?.title}"?`
                        : `Are you sure you want to archive the chat "${modal.chat?.title}"?`}
                </p>
            </Modal>
        </div>
    );
};

export default Sidebar;
