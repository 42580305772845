// DataSources.js
import React, { useState } from 'react';
import {
  Button,
  Modal,
  Form,
  Input,
  List,
  Avatar,
  Upload,
  message,
  Spin,
  Space,
  Typography,
} from 'antd';
import {
  UploadOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import axios from '../fetchWithCsrf';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from "../config";

const { TextArea } = Input;
const { Title } = Typography;

const DataSourcesPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const availableSources = [
    { name: 'PostgreSQL', icon: '/icons/Postgresql-icon.png', type: 'postgresql' },
    { name: 'MySQL', icon: '/icons/Mysql-icon.png', type: 'mysql' },
    { name: 'Snowflake', icon: '/icons/Snowflake-icon.png', type: 'snowflake' },
    { name: 'BigQuery', icon: '/icons/Bigquery-icon.png', type: 'bigquery' },
    { name: 'CSV', icon: '/icons/Csv-icon.png', type: 'csv' },
    { name: 'Microsoft SQL Server', icon: '/icons/Microsoftsql-icon.svg', type: 'mssql' },
  ];

  const openModal = (source) => {
    setSelectedSource(source);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedSource(null);
    form.resetFields();
  };

  const handleConnect = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('data_source_type', selectedSource.type);
      formData.append('customName', values.customName || `${selectedSource.name} Data Source`);

      Object.entries(values).forEach(([key, value]) => {
        if (key === 'file') {
          formData.append('file_path', value?.file?.originFileObj);
        } else {
          formData.append(key, value);
        }
      });

      const validationResponse = await axios.post(
        `${API_BASE_URL}/datasources/validate_connection/`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        }
      );

      if (validationResponse.status === 200) {
        const creationResponse = await axios.post(
          `${API_BASE_URL}/datasources/create_data_source/`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          }
        );

        if (creationResponse.status === 201) {
          message.success('Connection successful!');
          closeModal();
          setIsSuccessModalVisible(true);
        } else {
          message.error(creationResponse.data.error || 'Connection unsuccessful.');
        }
      } else {
        message.error(validationResponse.data.error || 'Connection unsuccessful.');
      }
    } catch (error) {
      message.error('Connection unsuccessful. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderFormFields = () => {
    if (!selectedSource) return null;

    const commonFields = (
      <>
        <Form.Item
          label="Data Source Name"
          name="customName"
          rules={[{ required: true, message: 'Please enter a data source name' }]}
        >
          <Input placeholder="Enter a custom name" />
        </Form.Item>
        <Form.Item
          label="Host"
          name="host"
          rules={[{ required: true, message: 'Please enter the host' }]}
        >
          <Input placeholder="e.g., localhost or IP address" />
        </Form.Item>
        <Form.Item
          label="Port"
          name="port"
          rules={[{ required: true, message: 'Please enter the port' }]}
        >
          <Input placeholder="e.g., 5432 for PostgreSQL, 3306 for MySQL, 1433 for MSSQL" />
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please enter the username' }]}
        >
          <Input placeholder="Enter the username" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please enter the password' }]}
        >
          <Input.Password placeholder="Enter the password" />
        </Form.Item>
      </>
    );

    switch (selectedSource.type) {
      case 'bigquery':
        return (
          <>
            <Form.Item
              label="Dataset ID"
              name="dataset_id"
              rules={[{ required: true, message: 'Please enter the Dataset ID' }]}
            >
              <Input placeholder="Enter Dataset ID" />
            </Form.Item>
            <Form.Item
              label="Service Account Credentials"
              name="credentials_json"
              rules={[{ required: true, message: 'Please enter your credentials' }]}
            >
              <TextArea rows={4} placeholder="Paste your Service Account credentials here" />
            </Form.Item>
          </>
        );
      case 'snowflake':
        return (
          <>
            {commonFields}
            <Form.Item
              label="Account"
              name="account"
              rules={[{ required: true, message: 'Please enter the account' }]}
            >
              <Input placeholder="Enter account" />
            </Form.Item>
            <Form.Item
              label="Warehouse"
              name="warehouse"
              rules={[{ required: true, message: 'Please enter the warehouse' }]}
            >
              <Input placeholder="Enter warehouse" />
            </Form.Item>
            <Form.Item
              label="Database"
              name="database"
              rules={[{ required: true, message: 'Please enter the database' }]}
            >
              <Input placeholder="Enter database" />
            </Form.Item>
            <Form.Item
              label="Schema"
              name="schema"
              rules={[{ required: true, message: 'Please enter the schema' }]}
            >
              <Input placeholder="Enter schema" />
            </Form.Item>
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: 'Please enter the role' }]}
            >
              <Input placeholder="Enter role" />
            </Form.Item>
          </>
        );
      case 'postgresql':
      case 'mysql':
      case 'mssql':
        return (
          <>
            {commonFields}
            <Form.Item
              label="Database Name"
              name="database"
              rules={[{ required: true, message: 'Please enter the database name' }]}
            >
              <Input placeholder="Enter the database name" />
            </Form.Item>
          </>
        );
      case 'csv':
        return (
          <Form.Item
            label="File"
            name="file"
            valuePropName="file"
            getValueFromEvent={(e) => e}
            rules={[{ required: true, message: 'Please upload a CSV file' }]}
          >
            <Upload beforeUpload={() => false} accept=".csv">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        );
      default:
        return null;
    }
  };

  return (
    <div className="data-sources-page">
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate('/my-data-sources')}
        style={{ marginBottom: '16px' }}
      >
        Back
      </Button>
      <Title level={2}>Add Data Source</Title>
      <List
        itemLayout="horizontal"
        dataSource={availableSources}
        renderItem={(source) => (
          <List.Item
            actions={[
              <Button type="primary" icon={<PlusOutlined />} onClick={() => openModal(source)}>
                Add
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={source.icon} size={40} />}
              title={source.name}
            />
          </List.Item>
        )}
        bordered
      />

      {/* Connect Modal */}
      <Modal
        title={`Connect to ${selectedSource?.name || 'Data Source'}`}
        visible={isModalVisible}
        onCancel={closeModal}
        footer={null}
        destroyOnClose
      >
        {loading ? (
          <div style={{ textAlign: 'center', padding: '50px 0' }}>
            <Spin tip="Connecting..." />
          </div>
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={handleConnect}
            initialValues={{ customName: '' }}
          >
            {renderFormFields()}
            <Form.Item>
              <Space>
                <Button onClick={closeModal}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Connect
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
      </Modal>

      {/* Success Modal */}
      <Modal
        title="Connection Successful"
        visible={isSuccessModalVisible}
        onCancel={() => setIsSuccessModalVisible(false)}
        footer={[
          <Button key="continue" type="primary" onClick={() => navigate('/my-data-sources')}>
            Continue
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <img
            src="/icons/success-icon.png"
            alt="Success"
            style={{ width: '100px', marginBottom: '20px' }}
          />
          <p>Your data source has been successfully connected.</p>
        </div>
      </Modal>
    </div>
  );
};

export default DataSourcesPage;
