import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { LayoutOutlined } from "@ant-design/icons"; // Importing the Ant Design icon

const EmptyDashboardPlaceholder = ({ onEnableEdit }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        border: "2px dashed #d9d9d9",
        borderRadius: "10px",
        padding: "50px",
        textAlign: "center",
        background: "#ffff",
        height: "500px",
        display: "flex", // Enable Flexbox
        flexDirection: "column", // Align content vertically
        justifyContent: "center", // Center content vertically
        alignItems: "center", // Center content horizontally
        gap: "20px", // Add gap between elements
      }}
    >
      {/* Replace Empty icon with LayoutOutlined icon */}
      <LayoutOutlined style={{ fontSize: "56px", color: "#8c8c8c" }} />
      <div style={{ fontSize: "16px", color: "#595959" }}>
        Start creating your custom dashboard by adding charts or new insights.
      </div>
      <div>
        <Button
          type="primary"
          onClick={onEnableEdit}
          style={{ marginRight: "10px" }}
        >
          Add Chart
        </Button>
        <Button type="default" onClick={() => navigate("/insights")}>
          Generate New Insight
        </Button>
      </div>
    </div>
  );
};

export default EmptyDashboardPlaceholder;
