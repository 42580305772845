// UnsavedChangesPrompt.jsx
import { useEffect, useContext } from 'react';
import { useBeforeUnload, UNSAFE_NavigationContext } from 'react-router-dom';

function UnsavedChangesPrompt({ when, message }) {
  // 1) Browser-level (refresh, close tab, browser back button)
  useBeforeUnload((event) => {
    if (when) {
      event.preventDefault();
      // Modern browsers ignore custom text, but we must set returnValue anyway:
      event.returnValue = message;
    }
  });

  // 2) In-app route changes (React Router v6)
  const { navigator } = useContext(UNSAFE_NavigationContext);
  useEffect(() => {
    if (!when) return;

    // Save original methods
    const origPush = navigator.push;
    const origReplace = navigator.replace;

    // Override push
    navigator.push = (...args) => {
      const confirmLeave = window.confirm(message);
      if (confirmLeave) origPush(...args);
    };

    // Override replace
    navigator.replace = (...args) => {
      const confirmLeave = window.confirm(message);
      if (confirmLeave) origReplace(...args);
    };

    // Cleanup on unmount or if 'when' becomes false
    return () => {
      navigator.push = origPush;
      navigator.replace = origReplace;
    };
  }, [when, message, navigator]);

  return null;
}

export default UnsavedChangesPrompt;
