import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Insights from './pages/Insights';
import Charts from './pages/Charts';
import DataSources from './pages/DataSources';
import Logout from './pages/Logout';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ProtectedRoute from './ProtectedRoute';
import { useAuth } from './AuthContext';
import axios from './fetchWithCsrf';
import MyDataSources from './pages/MyDataSources';
import Schema from './pages/Schema';
import EditElement from './pages/EditElement';
import Settings from './pages/Settings';
import ArchivedChats from './pages/ArchivedChats';
import UserAccessPage from './pages/UserAccessPage';
import Welcome from "./pages/Welcome";
import ActionManagement from "./pages/ActionManagement";
import API_BASE_URL from "./config";

// Import Ant Design's ConfigProvider
import { ConfigProvider } from 'antd';

const App = () => {
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    // Fetch the CSRF token when the app loads
    console.log('Fetching CSRF token on app load');
    axios
      .get(`${API_BASE_URL}/get-csrf-token/`)
      .then((response) => {
        console.log('CSRF token set:', response.data.csrfToken);
      })
      .catch((error) => {
        console.error('CSRF token error:', error);
      });
  }, []);

  console.log('App component rendered');

  return (
    // Wrap the app with ConfigProvider to customize the theme
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#348cf8',
          fontFamily: 'Poppins, sans-serif',
        },
      }}
    >
      <Router>
        <div className="app">
          <ConditionalNavbar isAuthenticated={isAuthenticated} />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/action-management" element={<ActionManagement />} />
            <Route
              path="/welcome"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Welcome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/insights"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Insights />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charts"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Charts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/data-sources"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <DataSources />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-data-sources"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <MyDataSources />
                </ProtectedRoute>
              }
            />
            <Route
              path="/datasources/:id/schema"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Schema />
                </ProtectedRoute>
              }
            />
            <Route
              path="/element/:id"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <EditElement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/:id"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/archived-chats"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ArchivedChats />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/user-access"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <UserAccessPage />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </Router>
    </ConfigProvider>
  );
};

// Conditional rendering of the Navbar based on the route and authentication status
const ConditionalNavbar = React.memo(({ isAuthenticated }) => {
  const location = useLocation();
  const hideNavbarPaths = [
    '/login',
    '/signup',
    '/forgot-password',
    '/logout',
    '/password_change',
    '/action-management',
  ];

  if (hideNavbarPaths.includes(location.pathname)) {
    return null;
  }

  return <Navbar isAuthenticated={isAuthenticated} />;
});

export default App;
