import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Table as AntTable, Button, Input, Spin, Space, Dropdown, Menu, message, Typography } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from '../fetchWithCsrf';
import './Schema.css';
import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import API_BASE_URL from "../config";

/**
 * A child component that handles PK/FK editing using Hooks.
 * This is where we keep local state for `isPrimaryKey`, `refTable`, `refColumn`.
 */
const RelationshipEditor = ({
  record,
  tableMetadata,
  columnMetadata,
  handleSaveRelationship
}) => {
  // For PK
  const [isPrimaryKey, setIsPrimaryKey] = useState(record.is_primary_key || false);

  // For FK
  // We consider “isForeignKey” true if `record.foreign_key` is NOT null/undefined.
  const [isForeignKey, setIsForeignKey] = useState(!!record.foreign_key);

  // The actual references
  const [refTable, setRefTable] = useState(record.foreign_key?.ref_table || '');
  const [refColumn, setRefColumn] = useState(record.foreign_key?.ref_col || '');

  // Called when user toggles "Primary Key"
  const handlePKToggle = async (checked) => {
    setIsPrimaryKey(checked);
    // If user toggles on "Primary Key," let's forcibly clear out foreign key data
    if (checked) {
      setIsForeignKey(false);
      setRefTable('');
      setRefColumn('');
    }

    await handleSaveRelationship({
      columnId: record.id,
      isPrimaryKey: checked,
      // If PK is on, foreign_key must be null:
      foreignKey: checked ? null : (isForeignKey
        ? { ref_table: refTable, ref_col: refColumn }
        : null),
    });
  };

  // Called when user toggles "Foreign Key"
  const handleFKToggle = async (checked) => {
    setIsForeignKey(checked);

    // If user unchecks "Foreign Key," remove the ref info
    if (!checked) {
      setRefTable('');
      setRefColumn('');
    }

    await handleSaveRelationship({
      columnId: record.id,
      isPrimaryKey,
      foreignKey: checked
        ? { ref_table: refTable, ref_col: refColumn }
        : null,
    });
  };

  // Called when user selects a table in the dropdown
  const selectTable = async (tableName) => {
    setRefTable(tableName);
    setRefColumn('');
    await handleSaveRelationship({
      columnId: record.id,
      isPrimaryKey,
      foreignKey: { ref_table: tableName, ref_col: '' },
    });
  };

  // Called when user selects a column in the dropdown
  const selectColumn = async (colName) => {
    setRefColumn(colName);
    await handleSaveRelationship({
      columnId: record.id,
      isPrimaryKey,
      foreignKey: { ref_table: refTable, ref_col: colName },
    });
  };

  // Build list of tables & columns
  const allTableNames = tableMetadata.map((t) => t.table_name);
  const tableObject = tableMetadata.find((t) => t.table_name === refTable);
  const possibleColumns = tableObject
    ? columnMetadata.filter((col) => col.table_metadata === tableObject.id)
    : [];

  // Build Menus
  const tableMenu = (
    <Menu>
      {allTableNames.map((tblName) => (
        <Menu.Item key={tblName} onClick={() => selectTable(tblName)}>
          {tblName}
        </Menu.Item>
      ))}
    </Menu>
  );

  const columnMenu = (
    <Menu>
      {possibleColumns.map((col) => (
        <Menu.Item key={col.id} onClick={() => selectColumn(col.column_name)}>
          {col.column_name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 8 }}>
      {/* Primary Key Checkbox */}
      <label>
        <input
          type="checkbox"
          checked={isPrimaryKey}
          onChange={(e) => handlePKToggle(e.target.checked)}
        />
        {' '}Primary Key
      </label>

      {/* Foreign Key Checkbox */}
      {!isPrimaryKey && (
        <label>
          <input
            type="checkbox"
            checked={isForeignKey}
            onChange={(e) => handleFKToggle(e.target.checked)}
          />
          {' '}Foreign Key
        </label>
      )}

      {/* If foreign key is checked, display the dropdowns */}
      {!isPrimaryKey && isForeignKey && (
        <>
          <Dropdown overlay={tableMenu} trigger={['click']}>
            <Button type="default">
              {refTable || 'Select a Table'} <DownOutlined />
            </Button>
          </Dropdown>

          {refTable && (
            <Dropdown overlay={columnMenu} trigger={['click']}>
              <Button type="default">
                {refColumn || 'Select a Column'} <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </>
      )}
    </div>
  );
};

/**
 * The main Schema component, showing table & column metadata, plus data preview.
 */
const Schema = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [tableMetadata, setTableMetadata] = useState([]);
  const [columnMetadata, setColumnMetadata] = useState([]);
  const [dataSourceName, setDataSourceName] = useState('');
  const [expandedTable, setExpandedTable] = useState(null);
  const [viewMode, setViewMode] = useState('metadata');
  const [dataPreview, setDataPreview] = useState([]);
  const [isDataPreviewLoading, setIsDataPreviewLoading] = useState(false);
  const [setDescription] = useState('');
  const [otherDataSources, setOtherDataSources] = useState([]);

  // --- Fetching metadata & data source info ---

  const fetchTableMetadata = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/table_metadata/?data_source=${id}`);
      setTableMetadata(response.data);
    } catch (error) {
      console.error('Error fetching table metadata:', error);
    }
  }, [id]);

  const fetchColumnMetadata = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/column_metadata/?data_source=${id}`);
      setColumnMetadata(response.data);
    } catch (error) {
      console.error('Error fetching column metadata:', error);
    }
  }, [id]);

  const fetchDataSourceName = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/datasources/${id}/`);
      setDataSourceName(response.data.name);
    } catch (error) {
      console.error('Error fetching data source name:', error);
    }
  }, [id]);

  const fetchOtherDataSources = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/datasources/`);
      setOtherDataSources(response.data.filter((ds) => ds.id !== parseInt(id)));
    } catch (error) {
      console.error('Error fetching other data sources:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchTableMetadata();
    fetchColumnMetadata();
    fetchDataSourceName();
    fetchOtherDataSources();
  }, [
    id,
    fetchTableMetadata,
    fetchColumnMetadata,
    fetchDataSourceName,
    fetchOtherDataSources,
  ]);

  const handleDataPreviewClick = useCallback(
    async (tableId = expandedTable) => {
      if (!tableId) return;
      try {
        setIsDataPreviewLoading(true);
        const response = await axios.get(
          `${API_BASE_URL}/fetch_preview_data/?table_id=${tableId}&limit=10`
        );
        setDataPreview(response.data);
      } catch (error) {
        console.error('Error fetching data preview:', error);
      } finally {
        setIsDataPreviewLoading(false);
      }
    },
    [expandedTable]
  );

  // Automatically expand the first table if none is selected
  useEffect(() => {
    if (tableMetadata.length > 0 && !expandedTable) {
      const firstTableId = tableMetadata[0].id;
      setExpandedTable(firstTableId);
      setDescription(tableMetadata[0].description || '');
    }
  }, [tableMetadata, expandedTable, setDescription]);

  // If in dataPreview mode, fetch 10 rows from the expanded table
  useEffect(() => {
    if (viewMode === 'dataPreview' && expandedTable) {
      handleDataPreviewClick(expandedTable);
    }
  }, [viewMode, expandedTable, handleDataPreviewClick]);

  // --- Handlers for data preview, saving descriptions, etc. ---

  const handleSaveDescription = async (desc, tableId) => {
    try {
      const table = tableMetadata.find((tbl) => tbl.id === tableId);
      if (!table) return;

      const updatedTable = { ...table, description: desc };
      await axios.put(`${API_BASE_URL}/table_metadata/${tableId}/`, updatedTable);

      setTableMetadata((prev) =>
        prev.map((tbl) => (tbl.id === tableId ? updatedTable : tbl))
      );

      message.success('Table description updated successfully');
    } catch (error) {
      console.error('Error saving table description:', error);
      message.error('Failed to save description');
    }
  };

  const handleSaveColumnDescription = async (desc, columnId) => {
    try {
      const column = columnMetadata.find((col) => col.id === columnId);
      if (!column) return;

      const updatedColumn = { ...column, description: desc };
      await axios.put(`${API_BASE_URL}/column_metadata/${columnId}/`, updatedColumn);

      setColumnMetadata((prev) =>
        prev.map((col) => (col.id === columnId ? updatedColumn : col))
      );

      message.success('Column description updated successfully');
    } catch (error) {
      console.error('Error saving column description:', error);
      message.error('Failed to save column description');
    }
  };

  /**
   * Saves the PK/FK relationship changes for a column.
   * Called by the RelationshipEditor child component.
   */
  const handleSaveRelationship = async ({ columnId, isPrimaryKey, foreignKey }) => {
    try {
      const column = columnMetadata.find((c) => c.id === columnId);
      if (!column) return;

      const updatedColumn = {
        ...column,
        is_primary_key: isPrimaryKey,
        foreign_key: isPrimaryKey ? null : foreignKey
      };

      await axios.put(
        `${API_BASE_URL}/column_metadata/${columnId}/`,
        updatedColumn
      );

      // Update local state so UI shows the change
      setColumnMetadata((prev) =>
        prev.map((col) => (col.id === columnId ? updatedColumn : col))
      );

      message.success('Relationship updated successfully');
    } catch (error) {
      console.error('Error saving relationship:', error);
      message.error('Failed to save relationship');
    }
  };


  // --- Render for "Data Preview" section ---

  const renderDataPreview = () => {
    if (isDataPreviewLoading) {
      return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
    }
    if (!dataPreview || dataPreview.length === 0) {
      return <p>No data available for preview.</p>;
    }

    const columns = Object.keys(dataPreview[0]).map((key) => ({
      title: key,
      dataIndex: key,
      key,
    }));

    return (
      <div style={{ position: 'relative' }}>
        <span
          style={{
            position: 'absolute',
            top: '-90px',
            right: '0',
            color: '#808080',
            fontSize: '12px',
          }}
        >
          * Data Preview displays 10 rows of data
        </span>

        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <AntTable
            columns={columns}
            dataSource={dataPreview}
            rowKey={(record, index) => index}
            pagination={false}
            size="small"
            style={{ minWidth: '1000px' }}
          />
        </div>
      </div>
    );
  };

  // --- Menus for selecting data source / table ---

  const menu = (
    <div className="scrollable-dropdown">
      <Menu>
        {otherDataSources.map((ds) => (
          <Menu.Item key={ds.id} onClick={() => navigate(`/datasources/${ds.id}/schema`)}>
            {ds.name}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );

  const tablesMenu = (
    <div className="scrollable-dropdown">
      <Menu>
        {tableMetadata.map((table) => (
          <Menu.Item
            key={table.id}
            onClick={() => {
              setExpandedTable(table.id);
              if (viewMode === 'dataPreview') {
                handleDataPreviewClick(table.id);
              }
            }}
          >
            {table.table_name}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );

  // --- Render function for the "Metadata" (tables & columns) ---

  const renderMetadata = () => {
    const expandedTableMetadata =
      tableMetadata.find((tbl) => tbl.id === expandedTable) || {};
    const columnsForThisTable = columnMetadata.filter(
      (col) => col.table_metadata === expandedTable
    );

    return (
      <>
        {/* Table-level metadata */}
        <AntTable
          dataSource={[expandedTableMetadata]}
          rowKey="id"
          tableLayout="fixed"
          pagination={false}
          columns={[
            {
              title: 'Table Name',
              dataIndex: 'table_name',
              key: 'table_name',
              width: 300,
              ellipsis: true,
            },
            {
              title: ' ',
              dataIndex: 'placeholder',
              key: 'placeholder',
              width: 300,
              render: () => null,
            },
            {
              title: 'Description',
              dataIndex: 'description',
              key: 'description',
              width: 500,
              ellipsis: true,
              render: (_, record) => (
                <Input.TextArea
                  defaultValue={record.description}
                  placeholder="Add table description"
                  rows={2}
                  onBlur={(e) => handleSaveDescription(e.target.value, record.id)}
                />
              ),
            },
            {
              title: ' ',
              dataIndex: 'placeholder2',
              key: 'placeholder2',
              width: 300,
              render: () => null,
            },
          ]}
        />

        {/* Column-level metadata */}
        <AntTable
          dataSource={columnsForThisTable}
          rowKey={(record) => record.id}
          tableLayout="fixed"
          pagination={false}
          columns={[
            {
              title: 'Column Name',
              dataIndex: 'column_name',
              key: 'column_name',
              width: 300,
              ellipsis: true,
            },
            {
              title: 'Type',
              dataIndex: 'column_type',
              key: 'column_type',
              width: 300,
              ellipsis: true,
            },
            {
              title: 'Description',
              dataIndex: 'description',
              key: 'description',
              width: 500,
              ellipsis: true,
              render: (_, record) => (
                <Input.TextArea
                  defaultValue={record.description}
                  placeholder="Add column description"
                  rows={2}
                  onBlur={(e) => handleSaveColumnDescription(e.target.value, record.id)}
                />
              ),
            },
            {
              title: (
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  Relationship
                  <Popover
                    content={
                      <div>
                        <p><strong>Primary Key:</strong> Uniquely identifies each row in a table.</p>
                        <p><strong>Foreign Key:</strong> References a primary key in another table, establishing a relationship.</p>
                      </div>
                    }
                  >
                    <InfoCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
                  </Popover>
                </span>
              ),
              key: 'relationship',
              width: 300,
              ellipsis: true,
              render: (_, record) => (
                <RelationshipEditor
                  record={record}
                  tableMetadata={tableMetadata}
                  columnMetadata={columnMetadata}
                  handleSaveRelationship={handleSaveRelationship}
                />
              ),
            },
          ]}
        />
      </>
    );
  };

  // --- Final return: layout with data source/table menus, the two "Columns / Data Preview" buttons, and the main view ---

  return (
    <div className="schema-page">
      <Typography.Link
        onClick={() => navigate('/my-data-sources')}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: 20,
          color: '#1677ff',   // Make link text black
        }}
      >
        <LeftOutlined style={{ marginRight: 4, color: '#1677ff' }} />
        Back
      </Typography.Link>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px'
      }}>
        {/* Left: Data Source & Table dropdowns */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          {/* Data Source dropdown */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span style={{ fontWeight: 'normal', fontSize: '14px' }}>Data Source:</span>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="default">
                {dataSourceName} <DownOutlined />
              </Button>
            </Dropdown>
          </div>

          {/* Table dropdown */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '30px' }}>
              Table:
            </span>
            <Dropdown overlay={tablesMenu} trigger={['click']}>
              <Button type="default">
                {tableMetadata.find((table) => table.id === expandedTable)?.table_name
                  || 'Select Table'}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>

        {/* Right: "Columns" / "Data Preview" buttons */}
        <Space>
          <Button
            type={viewMode === 'metadata' ? 'primary' : 'default'}
            onClick={() => setViewMode('metadata')}
          >
            Columns
          </Button>
          <Button
            type={viewMode === 'dataPreview' ? 'primary' : 'default'}
            onClick={() => {
              setViewMode('dataPreview');
              handleDataPreviewClick(expandedTable);
            }}
          >
            Data Preview
          </Button>
        </Space>
      </div>

      {viewMode === 'metadata' && renderMetadata()}
      {viewMode === 'dataPreview' && renderDataPreview()}
    </div>
  );
};

export default Schema;
