import React, { useState } from 'react';
import { Form, Input, Button, Typography, Alert } from 'antd';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import axios from '../fetchWithCsrf';
import { useAuth } from '../AuthContext';
import API_BASE_URL from "../config";

const { Title, Text } = Typography;
const auth = getAuth();

const validatePassword = (password) => {
  if (
    password.length < 8 ||
    !/[A-Z]/.test(password) ||
    !/\d/.test(password) ||
    !/[!@#$%^&*(),.?":{}|<>_\\-]/.test(password)
  ) {
    return "Password must contain at least 8 characters, 1 uppercase character, 1 numeric character, and 1 non-alphanumeric character.";
  }
  return "";
};

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { setAuth } = useAuth();

  const handleSubmit = async (values) => {
    const { name, email, password, confirmPassword } = values;

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const errorMessage = validatePassword(password);
    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      // Create user in Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send verification email with redirect URL
      await sendEmailVerification(user, {
        url: 'https://app.spruceai.app/login',
        handleCodeInApp: true
      });
      setSuccess('A verification email has been sent. Please check your inbox.');

      // Get Firebase ID token
      const idToken = await user.getIdToken();

      // Store auth state locally
      setAuth(true, idToken);

      // Create the user in your Django backend
      const payload = { name, email, idToken };
      const response = await axios.post(`${API_BASE_URL}/signup/`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        // You might choose to redirect immediately, or wait until email is verified.
        // For example, navigate('/verify-complete') might not be necessary if Firebase redirects.
        // Here, we simply wait for the redirect from Firebase.
        // navigate('/home');
      } else {
        setError(response.data.error || 'Failed to sign up. Please try again.');
      }
    } catch (err) {
      console.error('Sign-Up Error:', err);
      if (err.code === 'auth/email-already-in-use') {
        setError('An account with this email already exists. Please use a different email or log in.');
      } else {
        setError('Failed to sign up. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        padding: '20px',
      }}
    >
      <div style={{ textAlign: 'center', marginBottom: '30px' }}>
        <img src="/icons/LogoNoBackground.png" alt="Spruce Logo" style={{ width: '150px', marginBottom: '10px' }} />
      </div>

      <div
        style={{
          backgroundColor: '#fff',
          padding: '30px',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: '400px',
        }}
      >
        <Title level={4} style={{ textAlign: 'center', marginBottom: '20px' }}>
          Create your account
        </Title>

        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: '20px' }} />}
        {success && <Alert message={success} type="success" showIcon style={{ marginBottom: '20px' }} />}

        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter your name!' }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>

          <Form.Item
            label="Email address"
            name="email"
            rules={[
              { required: true, message: 'Please enter your email!' },
              { type: 'email', message: 'Invalid email address!' },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm your password" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: '100%', marginTop: '20px' }}
            >
              Sign up
            </Button>
          </Form.Item>
        </Form>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Text>
            Already have an account? <a href="/login" style={{ color: '#007bff' }}>Log in</a>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
