import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TableVisualization from './TableVisualization';

// Single palette for all charts:
const singlePalette = [
  '#348cf8',
  '#2f5f98',
  '#784e7a',
  '#a56988',
  '#d58797',
  '#f9968e',
  '#ffa475',
  '#ffba57',
  '#96e15a',
  '#36cb7f',
];

// Helper to create an RGBA color string from a hex code with desired alpha
const hexToRGBA = (hex, alpha) => {
  const cleanHex = hex.replace(/^#/, '');
  if (cleanHex.length !== 6) return `rgba(0,0,0,${alpha})`;
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// Lighten a hex color by a percentage (0–100)
const lightenHexColor = (hex, percent) => {
  const cleanHex = hex.replace(/^#/, '');
  if (cleanHex.length !== 6) return hex;
  const num = parseInt(cleanHex, 16);
  let r = (num >> 16) + Math.round((255 - (num >> 16)) * (percent / 100));
  let g = ((num >> 8) & 0x00FF) + Math.round((255 - ((num >> 8) & 0x00FF)) * (percent / 100));
  let b = (num & 0x0000FF) + Math.round((255 - (num & 0x0000FF)) * (percent / 100));
  r = r > 255 ? 255 : r;
  g = g > 255 ? 255 : g;
  b = b > 255 ? 255 : b;
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

// Apply colors to chart datasets, always using singlePalette,
// but do NOT force borderWidth so Chart.js can use defaults.
const applyBestPracticeColors = (chartData, finalChartType) => {
  // PIE / DOUGHNUT
  if (finalChartType === 'pie' || finalChartType === 'doughnut') {
    chartData.datasets.forEach((dataset) => {
      if (dataset._customColorUsed && dataset._customColor) {
        // Generate an array of colors for each label:
        dataset.backgroundColor = chartData.labels.map((_, i) => {
          // First slice uses the base custom color.
          if (i === 0) return dataset._customColor;
          // Each subsequent slice is progressively lighter.
          // For example, increase lightening by 10% per slice.
          return lightenHexColor(dataset._customColor, i * 10);
        });
        dataset.borderColor = dataset.backgroundColor;
      } else {
        // If no custom color, use the default palette.
        dataset.backgroundColor = chartData.labels.map(
          (_, i) => singlePalette[i % singlePalette.length]
        );
        dataset.borderColor = dataset.backgroundColor;
      }
    });
    return;
  }

  // For bar, line, or combo
  chartData.datasets.forEach((dataset, idx) => {
    // Only override if a custom color hasn't been applied
    if (!dataset._customColorUsed) {
      const color = singlePalette[idx % singlePalette.length];

      if (dataset.type === 'line' && dataset.fill === true) {
        dataset.borderColor = color;
        dataset.backgroundColor = hexToRGBA(color, 0.3);
        dataset.pointBackgroundColor = color;
        dataset.pointBorderColor = color;
      } else if (dataset.type === 'line') {
        dataset.borderColor = color;
        dataset.backgroundColor = color;
      } else if (dataset.type === 'bar') {
        dataset.backgroundColor = color;
        dataset.borderColor = color;
      } else {
        if (finalChartType === 'line') {
          dataset.borderColor = color;
          dataset.backgroundColor = color;
        } else {
          dataset.backgroundColor = color;
          dataset.borderColor = color;
        }
      }
    }
  });
};

const getContrastTextColor = (hexColor) => {
  if (!hexColor || typeof hexColor !== 'string') return '#000';
  const hex = hexColor.replace(/^#/, '');
  if (hex.length !== 6) return '#000';
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness < 128 ? '#fff' : '#000';
};

const Visualization = ({ config }) => {
  const canvasRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!config || typeof config !== 'object') return;

    // If table, destroy old Chart.js and show <TableVisualization />
    if (config.type === 'table') {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
      return;
    }

    // Must have labels/datasets
    if (!config.data || !Array.isArray(config.data.labels) || !Array.isArray(config.data.datasets)) {
      return;
    }

    // Clone config so we don't mutate the original
    const clonedConfig = JSON.parse(JSON.stringify(config));


    // Loop through all scales and add bold font styling for any axis with a title
    if (clonedConfig.options?.scales) {
      Object.keys(clonedConfig.options.scales).forEach((scaleKey) => {
        const scale = clonedConfig.options.scales[scaleKey];
        if (scale.title && scale.title.display) {
          scale.title.font = {
            // Preserve any existing font settings if needed
            ...scale.title.font,
            weight: 'bold',
            size: scale.title.font?.size || 12, // Set default size to 14 if not provided
          };
        }
      });
    }

    let finalChartType = (clonedConfig.type || 'bar').toLowerCase();

    // After cloning the config and determining finalChartType:
    if (clonedConfig.combo) {
      // Adjust each dataset so that if it already specifies a "line", keep it;
      // otherwise default to "bar". You can also set an order to control rendering.
      clonedConfig.data.datasets.forEach((dataset, idx) => {
        if (dataset.type && dataset.type.toLowerCase() === 'line') {
          dataset.type = 'line';
          dataset.order = 1; // Render lines on top, for example.
        } else {
          dataset.type = 'bar';
          dataset.order = 2;
        }
      });
    }

    // If you store `isArea: true` in config for area:
    if (finalChartType === 'line' && clonedConfig.isArea) {
      clonedConfig.data.datasets.forEach(ds => {
        ds.fill = true;
        ds.type = 'line';
      });
    }

    // Apply color palette
    applyBestPracticeColors(clonedConfig.data, finalChartType);

    // Data label offset logic
    const offsetValue = clonedConfig.dataLabelOffset || 0;
    const alignValue =
      offsetValue === 0 ? 'center' : offsetValue > 0 ? 'end' : 'start';

    const chartOptions = {
      ...clonedConfig.options,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        ...clonedConfig.options?.plugins,
        tooltip: {
          ...clonedConfig.options?.plugins?.tooltip,
          enabled: true,
        },
        datalabels: {
          display: clonedConfig.showDataLabels ?? false,
          anchor: 'center',
          align: alignValue,
          offset: Math.abs(offsetValue),
          color: (ctx) => {
            const chartType = ctx.chart.config.type;
            const datasetType = ctx.dataset.type?.toLowerCase() || '';
            if (chartType === 'line' || datasetType === 'line') {
              return '#000';
            }
            // Otherwise compute contrast
            let bg = ctx.dataset.backgroundColor;
            if (Array.isArray(bg)) {
              bg = bg[ctx.dataIndex % bg.length];
            }
            return getContrastTextColor(bg);
          },
        },
      },
    };


    // Hide scales if pie/doughnut
    if (finalChartType === 'pie' || finalChartType === 'doughnut') {
      chartOptions.scales = {};
    }

    // Destroy old instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Create new
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;

    chartInstanceRef.current = new Chart(ctx, {
      type: finalChartType,
      data: clonedConfig.data,
      options: chartOptions,
      plugins: [ChartDataLabels],
    });

    // Cleanup
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [config]);

  // If it's a table, render that instead
  if (config?.type === 'table') {
    return <TableVisualization config={config} />;
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default React.memo(Visualization);
