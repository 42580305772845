import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../fetchWithCsrf';
import Visualization from '../components/Visualization';
import './EditElement.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PptxGenJS from 'pptxgenjs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import {
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Input,
  Select,
  Form,
  Switch,
  InputNumber,
  Modal, // ADDED
  ColorPicker,
  Row,
  Col,
} from 'antd';
import { LeftOutlined, DownOutlined, ClearOutlined } from '@ant-design/icons';
import UnsavedChangesPrompt from '../components/UnsavedChangesPrompt';
import TableVisualization from "../components/TableVisualization";
import { hexToRGBA } from '../components/hexToRGBA'; // adjust the path as needed
import API_BASE_URL from "../config";



const EditElement = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [element, setElement] = useState(null);
  const [config, setConfig] = useState(null);

  // Used by UnsavedChangesPrompt & beforeunload:
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // For share dropdown
  const shareDropdownRef = useRef(null);
  const [setIsShareDropdownOpen] = useState(false);

  // Chart-related state
  const [selectedChartType, setSelectedChartType] = useState('');
  const [userQuery, setUserQuery] = useState('');
  const [reconfiguredMessage, setReconfiguredMessage] = useState(null);
  const [selectedPalette] = useState('Blue');
  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState(0);

  const [savedScales, setSavedScales] = useState(null);

  // Near your other state
  const [secondYAxisLabel, setSecondYAxisLabel] = useState('');

  // --- ADDED OR MODIFIED CODE (1/2): for "View Data" modal ---
  const [isDataModalOpen, setIsDataModalOpen] = useState(false);
  const openDataModal = () => {
    setIsDataModalOpen(true);
  };
  const closeDataModal = () => {
    setIsDataModalOpen(false);
  };
  // -----------------------------------------------------------

  // 1) Fetch element on mount
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/get-element/${id}/`, { withCredentials: true })
      .then((response) => {
        console.log('Fetched Element Data:', response.data);
        const fetchedConfig = response.data.chart_config;
        const initialChartType = mapConfigToChartType(fetchedConfig);
        setElement(response.data);
        setConfig(fetchedConfig);
        setSelectedChartType(initialChartType);
      })
      .catch((error) => {
        console.error('Error fetching element:', error);
      });
  }, [id]);

  // 2) Close share dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareDropdownRef.current && !shareDropdownRef.current.contains(event.target)) {
        setIsShareDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsShareDropdownOpen]);

  // 3) Browser-level prompt: refresh, tab close, or browser back button
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        // The custom text is often ignored by modern browsers, but must be set:
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  // Map config to chart type
  // Map config to chart type
  const mapConfigToChartType = (conf) => {
    if (!conf) return '';

    // 1. Use explicit flags if they exist
    if (conf.combo) return 'Combo';
    if (conf.isArea) return 'Area';

    // 2. Ensure we have a type
    if (!conf.type) return '';
    const chartType = conf.type.toLowerCase();

    // 3. Auto-detect a combo if datasets include both "bar" and "line"
    const datasets = conf?.data?.datasets;
    if (datasets && datasets.length > 0) {
      const types = datasets.map((d) =>
        d.type ? d.type.toLowerCase() : chartType
      );
      const hasBar = types.includes('bar');
      const hasLine = types.includes('line');
      if (hasBar && hasLine) return 'Combo';
    }

    // 4. Standard mapping for single types
    if (chartType === 'line') return 'Line';
    if (chartType === 'bar') return 'Bar';
    if (chartType === 'pie') return 'Pie';

    return chartType.charAt(0).toUpperCase() + chartType.slice(1);
  };

  // Save changes to the server
  const handleSaveChanges = () => {
    axios
      .post(
        `${API_BASE_URL}/edit-element/${id}/`,
        { chart_config: config },
        { withCredentials: true }
      )
      .then(() => {
        setHasUnsavedChanges(false);
        message.success('Element saved successfully');
      })
      .catch((error) => {
        console.error('Error saving changes:', error);
        message.error('An error occurred while saving your changes. Please try again.');
      });
  };


  const renderDynamicControls = () => {
    // Guard: we need config, config.data, config.data.labels, config.data.datasets
    if (!config || !config.data || !Array.isArray(config.data.labels) || !Array.isArray(config.data.datasets)) {
      console.error('Invalid chart config structure:', config);
      return null;
    }

    // A set of chart types you want the user to pick from
    const availableChartTypes = [
      'Table',
      'Bar',
      'Line',
      'Area',
      'Pie',
      'Combo',
    ];

    // We'll map the raw config.type to selectedChartType:
    // e.g. config.type="bar" => "Bar", config.type="line" => "Line", ...
    // (In practice, you might have a function to do this mapping.)
    const mapChartTypeToFriendly = (rawType) => {
      if (!rawType) return '';
      if (rawType.toLowerCase() === 'line' && config.isArea) return 'Area';
      if (rawType.toLowerCase() === 'line') return 'Line';
      if (rawType.toLowerCase() === 'bar');
      if (rawType.toLowerCase() === 'pie') return 'Pie';
      // Check combos: if any dataset has type=bar, others type=line => "Bar Line Combo"
      // ...
      return rawType[0].toUpperCase() + rawType.slice(1).toLowerCase();
    };

    // We'll do the reverse when user picks from the dropdown:
    // We'll do the reverse when user picks from the dropdown:
    const handleChartTypeChange = (newFriendlyType) => {
      let rawType = '';
      let isCombo = false;

      switch (newFriendlyType) {
        case 'Table':
          rawType = 'table';
          break;
        case 'Bar':
          rawType = 'bar';
          break;
        case 'Line':
          rawType = 'line';
          break;
        case 'Area':
          // We'll treat "Area" as a line with fill = true
          rawType = 'line';
          break;
        case 'Pie':
          rawType = 'pie';
          break;
        case 'Combo':
          // We'll default the main chart type to 'bar'
          rawType = 'bar';
          isCombo = true;
          break;
        default:
          rawType = 'bar';
      }

      setConfig((prev) => {
        // --- 1) If switching to Pie (or Doughnut) ---
        if (rawType === 'pie' || rawType === 'doughnut') {
          if (prev.options?.scales) {
            setSavedScales(prev.options.scales);
          }
          const newDatasets = (prev.data?.datasets || []).map((ds) => ({
            ...ds,
            type: rawType, // 'pie'
          }));
          return {
            ...prev,
            type: rawType,
            combo: isCombo,
            isArea: (newFriendlyType === 'Area'),
            options: {
              ...prev.options,
              scales: {}, // Clear scales for pie
            },
            data: {
              ...prev.data,
              datasets: newDatasets,
            },
          };
        } else {
          // --- 2) For Bar/Line/Area/Combo ---
          const oldScales = savedScales || prev.options?.scales || {};
          let newScales = { ...oldScales };
          if (!isCombo) {
            newScales.x = { ...(oldScales.x || {}), stacked: false };
            newScales.y = { ...(oldScales.y || {}), stacked: false };
          }

          // Update each dataset:
          let newDatasets = (prev.data?.datasets || []).map((ds, idx) => {
            // For combo mode, set the type based on index;
            // otherwise, force all datasets to match rawType.
            let updatedDs = { ...ds };
            if (isCombo) {
              updatedDs.type = idx === 0 ? 'bar' : 'line';
            } else {
              updatedDs.type = rawType;
            }

            // Recalculate colors if a custom color was chosen (stored as _customColor)
            if (ds._customColor) {
              if (newFriendlyType === 'Area') {
                updatedDs.borderColor = ds._customColor;
                updatedDs.backgroundColor = hexToRGBA(ds._customColor, 0.3);
                updatedDs.pointBackgroundColor = ds._customColor;
                updatedDs.pointBorderColor = ds._customColor;
              } else {
                updatedDs.borderColor = ds._customColor;
                updatedDs.backgroundColor = ds._customColor;
                updatedDs.pointBackgroundColor = ds._customColor;
                updatedDs.pointBorderColor = ds._customColor;
              }
            }
            return updatedDs;
          });

          return {
            ...prev,
            type: rawType,
            combo: isCombo,
            isArea: (newFriendlyType === 'Area'),
            options: {
              ...prev.options,
              scales: newScales,
            },
            data: {
              ...prev.data,
              datasets: newDatasets,
            },
          };
        }
      });

      setSelectedChartType(newFriendlyType);
      setHasUnsavedChanges(true);
    };

    const handleClearCustomColor = () => {
      setConfig((prev) => {
        const newDatasets = prev.data.datasets.map((ds, idx) => {
          if (idx === selectedDatasetIndex) {
            // Destructure to remove the custom color flags
            const { _customColor, _customColorUsed, ...rest } = ds;
            // Remove any directly set color fields so that default palette logic takes over
            delete rest.borderColor;
            delete rest.backgroundColor;
            delete rest.pointBackgroundColor;
            delete rest.pointBorderColor;
            return rest;
          }
          return ds;
        });
        return {
          ...prev,
          data: {
            ...prev.data,
            datasets: newDatasets,
          },
        };
      });
    };

    // We'll parse the "friendly" type from config.type
    const friendlyChartType = selectedChartType || mapChartTypeToFriendly(config.type);

    // For chart title, legend, etc. we read from config.options
    const chartTitle = config.options?.plugins?.title?.text || '';
    const legendDisplay = config.options?.plugins?.legend?.display ?? true;
    const xAxisLabel = config.options?.scales?.x?.title?.text || '';
    const yAxisLabel = config.options?.scales?.y?.title?.text || '';
    const showDataLabels = config.showDataLabels ?? false;
    const dataLabelOffset = config.dataLabelOffset ?? 0;

    const uniqueYAxisIDs = new Set(
      config.data.datasets.map(ds => ds.yAxisID || 'y')
    );
    const hasSecondAxis = uniqueYAxisIDs.size > 1;


    return (
      <Form layout="vertical">
        <Form.Item label="Chart Type">
          <Select
            value={friendlyChartType}
            onChange={handleChartTypeChange}
            style={{ width: '100%' }}
          >
            {availableChartTypes.map((ct) => (
              <Select.Option key={ct} value={ct}>
                {ct}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Chart Title">
          <Input
            value={chartTitle}
            onChange={(e) => {
              setConfig((prev) => ({
                ...prev,
                options: {
                  ...prev.options,
                  plugins: {
                    ...prev.options?.plugins,
                    title: {
                      ...prev.options?.plugins?.title,
                      display: true,
                      text: e.target.value,
                    },
                  },
                },
              }));
              setHasUnsavedChanges(true);
            }}
          />
        </Form.Item>

        <Form.Item label="X-Axis Label">
          <Input
            value={xAxisLabel}
            onChange={(e) => {
              setConfig((prev) => ({
                ...prev,
                options: {
                  ...prev.options,
                  scales: {
                    ...prev.options?.scales,
                    x: {
                      ...prev.options?.scales?.x,
                      title: {
                        // Ensures the label will be shown
                        display: true,
                        text: e.target.value,
                      },
                    },
                    y: {
                      ...(prev.options?.scales?.y || {}),
                    },
                  },
                },
              }));
              setHasUnsavedChanges(true);
            }}
          />
        </Form.Item>

        <Form.Item label="Y-Axis Label">
          <Input
            value={yAxisLabel}
            onChange={(e) => {
              setConfig((prev) => ({
                ...prev,
                options: {
                  ...prev.options,
                  scales: {
                    ...prev.options?.scales,
                    x: { ...(prev.options?.scales?.x || {}) },
                    y: {
                      ...prev.options?.scales?.y,
                      title: {
                        ...prev.options?.scales?.y?.title,
                        text: e.target.value,
                      },
                    },
                  },
                },
              }));
              setHasUnsavedChanges(true);
            }}
          />
        </Form.Item>

        {/* ---- NEW: Conditionally show Second Y-Axis Label if a second axis exists ---- */}
        {hasSecondAxis && (
          <Form.Item label="Second Y-Axis Label">
            <Input
              value={secondYAxisLabel}
              onChange={(e) => {
                setSecondYAxisLabel(e.target.value);
                setHasUnsavedChanges(true);
                // Update config.options.scales for the second axis.
                setConfig((prev) => {
                  const newOptions = { ...prev.options };
                  if (!newOptions.scales) newOptions.scales = {};
                  newOptions.scales.y1 = {
                    ...(newOptions.scales.y1 || {}),
                    title: {
                      display: true,
                      text: e.target.value,
                    },
                    // You can include other settings (like position, beginAtZero, etc.) here.
                    position: 'right',
                    beginAtZero: true,
                    grid: { drawOnChartArea: false }
                  };
                  return {
                    ...prev,
                    options: newOptions,
                  };
                });
              }}
              placeholder=""
            />
          </Form.Item>
        )}

        {/* If "Combo": let each dataset pick bar/line + optional stack toggle if "bar" */}
        {friendlyChartType === 'Combo' && (
          <>
            <Form.Item label="Stack Bars">
              <Switch
                checked={
                  config.options?.scales?.x?.stacked === true &&
                  config.options?.scales?.y?.stacked === true
                }
                onChange={(checked) => {
                  setConfig((prev) => ({
                    ...prev,
                    options: {
                      ...prev.options,
                      scales: {
                        ...prev.options?.scales,
                        x: { ...(prev.options?.scales?.x || {}), stacked: checked },
                        y: { ...(prev.options?.scales?.y || {}), stacked: checked },
                      },
                    },
                  }));
                  setHasUnsavedChanges(true);
                }}
              />
            </Form.Item>

            {/* Datasets */}
            <Form.Item label="Dataset Chart Types">
              {config.data.datasets.map((ds, idx) => {
                const currentType = ds.type || 'bar';

                return (
                  <div key={idx} style={{ marginBottom: 8 }}>
                    <Select
                      style={{ width: '100%' }}
                      value={currentType}
                      onChange={(val) => {
                        // user picks "bar" or "line"
                        const newDatasets = [...config.data.datasets];
                        newDatasets[idx] = { ...newDatasets[idx], type: val };
                        setConfig((prev) => ({
                          ...prev,
                          data: { ...prev.data, datasets: newDatasets },
                        }));
                        setHasUnsavedChanges(true);
                      }}
                    >
                      <Select.Option value="bar">Bar</Select.Option>
                      <Select.Option value="line">Line</Select.Option>
                    </Select>
                  </div>
                );
              })}
            </Form.Item>
          </>
        )}

        {/* If "Bar": show "Stack Bars?" */}
        {friendlyChartType === 'Bar' && (
          <>
            <Form.Item label="Stack Bars">
              <Switch
                checked={
                  config.options?.scales?.x?.stacked === true &&
                  config.options?.scales?.y?.stacked === true
                }
                onChange={(checked) => {
                  setConfig((prev) => ({
                    ...prev,
                    options: {
                      ...prev.options,
                      scales: {
                        ...prev.options?.scales,
                        x: { ...(prev.options?.scales?.x || {}), stacked: checked },
                        y: { ...(prev.options?.scales?.y || {}), stacked: checked },
                      },
                    },
                  }));
                  setHasUnsavedChanges(true);
                }}
              />
            </Form.Item>
          </>
        )}

        <Form.Item label="Legend">
          <Switch
            checked={legendDisplay}
            onChange={(checked) => {
              setConfig((prev) => ({
                ...prev,
                options: {
                  ...prev.options,
                  plugins: {
                    ...prev.options?.plugins,
                    legend: {
                      ...prev.options?.plugins?.legend,
                      display: checked,
                    },
                  },
                },
              }));
              setHasUnsavedChanges(true);
            }}
          />
        </Form.Item>

        {/* Only show these Inputs if legend.display is true */}
        {legendDisplay && config.data?.datasets?.map((dataset, idx) => (
          <Form.Item key={idx} label={`Legend Name for Dataset #${idx + 1}`}>
            <Input
              value={dataset.label || ''}
              onChange={(e) => {
                // 1) Clone the old datasets array
                const newDatasets = [...config.data.datasets];
                // 2) Update the label in the specific dataset
                newDatasets[idx] = {
                  ...newDatasets[idx],
                  label: e.target.value,
                };

                // 3) Write back into config
                setConfig((prev) => ({
                  ...prev,
                  data: {
                    ...prev.data,
                    datasets: newDatasets,
                  },
                }));

                setHasUnsavedChanges(true);
              }}
            />
          </Form.Item>
        ))}

        <Form.Item label="Data Labels">
          <Switch
            checked={showDataLabels}
            onChange={(checked) => {
              setConfig((prev) => ({
                ...prev,
                showDataLabels: checked,
              }));
              setHasUnsavedChanges(true);
            }}
          />
        </Form.Item>

        {showDataLabels && (
        <Form.Item label="Data Label Offset">
          <InputNumber
            value={dataLabelOffset}
            onChange={(val) => {
              setConfig((prev) => ({
                ...prev,
                dataLabelOffset: Number(val),
              }));
              setHasUnsavedChanges(true);
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        )}

        {/* Show/hide gridlines */}
        <Form.Item label="Show Gridlines">
          <Switch
            checked={
              // If they haven’t set them, default is true
              config.options?.scales?.x?.grid?.display !== false &&
              config.options?.scales?.y?.grid?.display !== false
            }
            onChange={(checked) => {
              setConfig((prev) => ({
                ...prev,
                options: {
                  ...prev.options,
                  scales: {
                    ...prev.options?.scales,
                    x: {
                      ...prev.options?.scales?.x,
                      grid: {
                        ...(prev.options?.scales?.x?.grid || {}),
                        display: checked,
                        drawBorder: checked,
                      },
                    },
                    y: {
                      ...prev.options?.scales?.y,
                      grid: {
                        ...(prev.options?.scales?.y?.grid || {}),
                        display: checked,
                        drawBorder: checked,
                      },
                    },
                  },
                },
              }));
              setHasUnsavedChanges(true);
            }}
          />
        </Form.Item>

        <Form.Item label="Custom Color" style={{ marginBottom: 16 }}>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    value={selectedDatasetIndex}
                    onChange={(value) => setSelectedDatasetIndex(value)}
                    style={{ width: 120 }}
                  >
                    {config.data.datasets.map((ds, index) => (
                      <Select.Option key={index} value={index}>
                        {ds.label || `Dataset ${index + 1}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  style={{ marginBottom: 0 }}
                >
                  <ColorPicker
                    onChange={(colorObj) => {
                      const colorString = colorObj.toHexString();
                      setConfig((prev) => {
                        const newDatasets = prev.data.datasets.map((ds, idx) => {
                          if (idx === selectedDatasetIndex) {
                            const datasetType = ds.type
                              ? ds.type.toLowerCase()
                              : prev.type.toLowerCase();
                            if (datasetType === 'line') {
                              return {
                                ...ds,
                                _customColor: colorString, // store the base color
                                borderColor: colorString,
                                backgroundColor: prev.isArea
                                  ? hexToRGBA(colorString, 0.3)
                                  : colorString,
                                pointBackgroundColor: colorString,
                                pointBorderColor: colorString,
                                _customColorUsed: true,
                              };
                            } else if (datasetType === 'bar') {
                              return {
                                ...ds,
                                _customColor: colorString,
                                backgroundColor: colorString,
                                borderColor: colorString,
                                _customColorUsed: true,
                              };
                            }
                            // Fallback:
                            return {
                              ...ds,
                              _customColor: colorString,
                              backgroundColor: colorString,
                              _customColorUsed: true,
                            };
                          }
                          return ds;
                        });
                        return {
                          ...prev,
                          data: { ...prev.data, datasets: newDatasets },
                        };
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button type="text" icon={<ClearOutlined />} onClick={handleClearCustomColor} />
              </Col>
            </Row>
          </Form.Item>

        <Form.Item>
          <Button onClick={openDataModal} style={{ width: '100%' }}>
            View Data
          </Button>
        </Form.Item>
      </Form>
    );
  };

  // Reconfigure chart
  const handleReconfigureChart = async () => {
    if (!userQuery) return;
    try {
      const response = await axios.post(
        `${API_BASE_URL}/fetch_data/`,
        {
          user_input: userQuery,
          chat_id: element.id,
          conversation_history: element.conversation_history,
          chart_config: config,
          sql_query: element.sql_query,
          queryResults: element.data,
        },
        { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
      );

      const { cleaned_output, chart_config, message_id, data } = response.data;
      const newSystemMessage = {
        from_user: 'system',
        text: cleaned_output || 'No response from GPT.',
        messageId: message_id,
        data,
        chartConfig: chart_config,
        sqlQuery: response.data.sql_query,
      };

      if (chart_config && Object.keys(chart_config).length > 0) {
        newSystemMessage.type = 'chart';
        newSystemMessage.cleanedOutput = cleaned_output;
      }

      setConfig(chart_config);
      setReconfiguredMessage(newSystemMessage);
      setHasUnsavedChanges(true);
    } catch (error) {
      console.error('Error reconfiguring chart:', error);
    }
  };

  // Export logic (unchanged, still referencing element.data)
  const handleExportToPDF = () => {
    const input = document.querySelector('.visualization-container');
    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save(`${element.name || 'element'}.pdf`);
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  };

  const handleExportToPPTX = () => {
    const pptx = new PptxGenJS();
    const slide = pptx.addSlide();

    const input = document.querySelector('.visualization-container');
    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        slide.addImage({
          data: imgData,
          x: 0,
          y: 0,
          w: pptx.width,
          h: (canvas.height * pptx.width) / canvas.width,
        });
        pptx.writeFile({ fileName: `${element.name || 'element'}.pptx` });
      })
      .catch((error) => {
        console.error('Error generating PPTX:', error);
      });
  };

  const handleExportDataToCSV = () => {
    // STILL referencing element.data unchanged
    const data = element.data;
    if (!data || data.length === 0) {
      alert('No data available to export.');
      return;
    }

    let dataArray;
    if (Array.isArray(data)) {
      dataArray = data.flat();
    } else {
      dataArray = Object.values(data);
    }

    if (!dataArray.length) {
      alert('No data available to export.');
      return;
    }

    if (typeof dataArray[0] !== 'object' || dataArray[0] === null) {
      alert('Data format is not supported.');
      return;
    }

    const headers = Object.keys(dataArray[0]);
    const csvRows = [headers.join(',')];
    dataArray.forEach((row) => {
      const values = headers.map((header) => `"${row[header] || ''}"`);
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'data_export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportDataToExcel = () => {
    // STILL referencing element.data unchanged
    const data = element.data;
    if (!data || data.length === 0) {
      alert('No data available to export.');
      return;
    }

    let dataArray;
    if (Array.isArray(data)) {
      dataArray = data.flat();
    } else {
      dataArray = Object.values(data);
    }

    if (!dataArray.length) {
      alert('No data available to export.');
      return;
    }

    if (typeof dataArray[0] !== 'object' || dataArray[0] === null) {
      alert('Data format is not supported.');
      return;
    }

    const headers = Object.keys(dataArray[0]);
    const worksheetData = [headers];
    dataArray.forEach((row) => {
      const values = headers.map((header) => row[header] || '');
      worksheetData.push(values);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'data_export.xlsx');
  };

  const handleShareViaEmail = () => {
    const subject = encodeURIComponent(`Sharing Element: ${element.name}`);
    const body = encodeURIComponent(
      `Hi,\n\nI wanted to share this element with you: ${element.name}.\n\nBest regards,\n[Your Name]`
    );
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  // Share/export menu
  const shareExportMenu = (
    <Menu>
      <Menu.Item key="email" onClick={handleShareViaEmail}>
        Share via Email
      </Menu.Item>
      <Menu.Item key="pdf" onClick={handleExportToPDF}>
        Export to PDF
      </Menu.Item>
      <Menu.Item key="pptx" onClick={handleExportToPPTX}>
        Export to PowerPoint
      </Menu.Item>
      <Menu.Item key="excel" onClick={handleExportDataToExcel}>
        Export Data to Excel
      </Menu.Item>
      <Menu.Item key="csv" onClick={handleExportDataToCSV}>
        Export Data to CSV
      </Menu.Item>
    </Menu>
  );

  if (!element) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/*
        1) UnsavedChangesPrompt catches in-app nav
        2) beforeunload in effect catches tab close / refresh
        => only the default browser confirm is shown
      */}
      <UnsavedChangesPrompt
        when={hasUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />

      <div className="edit-element">
        {/* Replace the old handleNavigationAway with direct navigate */}
        <div className="back-button-container">
          <Typography.Link
            onClick={() => navigate('/charts')}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: 16,
              color: '#1677ff'
            }}
          >
            <LeftOutlined style={{ marginRight: 4 }} />
            Back
          </Typography.Link>
        </div>

        <div className="edit-element-header">
          <Typography.Title level={4} style={{ margin: 0, flex: 1 }}>
            {element.name}
          </Typography.Title>
          <div className="edit-element-actions">
            <Button
              type="primary"
              onClick={handleSaveChanges}
              style={{ marginRight: 8 }}
            >
              Save Changes
            </Button>
            <Dropdown overlay={shareExportMenu} placement="bottomRight">
              <Button>
                Share/Export <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>

        <div className="edit-element-container">
          <div className="visualization-container">
            <Visualization
              config={config}
              requestedChartType={selectedChartType}
              selectedPalette={selectedPalette}
            />
          </div>
          <div className="edit-element-sidebar">
            {renderDynamicControls()}
          </div>
        </div>

        {/* Reconfigure Chart Chat Style */}
        <div className="chat-interface-reconfigure-interface">
          <div className="chat-window-reconfigure-window">
            {reconfiguredMessage && (
              <div className="chat-message-gpt-message">
                {reconfiguredMessage.text}
              </div>
            )}
          </div>
          <div className="chat-input-section-edit-element">
            <Input
              value={userQuery}
              onChange={(e) => setUserQuery(e.target.value)}
              placeholder="E.g. Change time range to last 6 months"
              onPressEnter={handleReconfigureChart}
              className="reconfigure-query-input"
              style={{ width: 'calc(100% - 120px)', marginRight: 8 }}
            />
            <Button type="primary" onClick={handleReconfigureChart}>
              Reconfigure Chart
            </Button>
          </div>
        </div>
      </div>

      {/* ADDED: "View Data" Modal */}
      <Modal
        visible={isDataModalOpen}
        footer={null}
        onCancel={closeDataModal}
        width={800}
        bodyStyle={{ padding: '20px' }}
      >
        {element.sql_query && (
          <p style={{ marginBottom: '16px' }}>
            <strong>SQL Query:</strong>
            <br />
            <br />
            {element.sql_query}
          </p>
        )}

        {/* We no longer rely on `Array.isArray(config.data)`.
            Instead, we just pass the chartJs object {labels, datasets}
            into <TableVisualization config={{ data: config.data }} />
            so it uses `chartJsDataToAntdTable()` internally.
        */}
        {config?.data ? (
          <>
            <p>
              <strong>Query Results:</strong>
            </p>
            <div style={{ marginTop: '16px' }}>
              <TableVisualization config={config} />
            </div>
          </>
        ) : (
          <p>No data available.</p>
        )}
      </Modal>
      {/* End "View Data" Modal */}
    </>
  );
};

export default EditElement;
